import React, { useEffect, useState } from "react";
import puzzle_exemple from "../../../Assets/img/Mypuzzles/puzzle_exemple.png";
import gallery from "../../../Assets/vectors/Mypuzzles/gallery.svg";
import docs from "../../../Assets/vectors/Mypuzzles/docs.svg";
import link from "../../../Assets/vectors/Mypuzzles/link.svg";
import close from "../../../Assets/vectors/Mypuzzles/close.svg";
const Specifications = ({
  quitCreation,
  gotoConfirm,
  fetchFileSpecification,
  fetchDataSpecs,
}) => {
  const [file, setFile] = useState(null);
  const [isLinkActive, setIsLinkActive] = useState(false);
  const [descriptionSpecsValue, setDescriptionSpecsValue] = useState("");
  const [linkSpecsValue, setLinkSpecsValue] = useState("");
  const [allDataSpecs, setAllDataSpecs] = useState({});

  useEffect(() => {
    let data = allDataSpecs;
    data.description_specs = descriptionSpecsValue;
    data.link_specs = linkSpecsValue;
    setAllDataSpecs(data);
  }, [descriptionSpecsValue, linkSpecsValue]);

  function handleChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      setFile(file);
    }
  }
  const quit = () => {
    quitCreation();
  };
  const handleNext = () => {
    if (file) {
      fetchFileSpecification(file);
    }
    fetchDataSpecs(allDataSpecs);
    gotoConfirm();
  };

  const handleCloseDocs = () => {
    setFile(null);
  };

  return (
    <div className="mypuzzles_specification">
      <div className="mypuzzles_product">
        <div className="flex-elem flex-fd-c mypuzzles_product_first">
          <div className="">
            <span className="mypuzzles_product_second_product_information">
              Create a new puzzle
            </span>
          </div>
          <img className="mypuzzles_product_first_img" src={puzzle_exemple} />
        </div>

        <div className="flex-fd-c mypuzzles_product_second">
          <div className="mypuzzles_product_second_product">
            <span className="mypuzzles_product_second_product_information">
              Specification
              <span className="mypuzzles_specification_optional">
                (Optional)
              </span>
            </span>
            <span className="mypuzzles_product_second_product_step">
              step 3 of 3
            </span>
          </div>

          <div className="flex-elem flex-fd-c mypuzzles_specification_specs">
            <span className="mypuzzles_specification_specs_title">
              Specs Description
            </span>
            <div className="mypuzzles_product_second_title_input">
              <input
                type="text"
                className="flex-elem mypuzzles_product_second_title_input_content mypuzzles_product_second_title_input_description"
                placeholder="sescribe the specification"
                onChange={(e) => setDescriptionSpecsValue(e.target.value)}
              />
            </div>
          </div>
          <div className="flex-elem flex-fd-c mypuzzles_specification_file-link">
            <span className="mypuzzles_specification_specs_title">
              File or Link
            </span>
            <div className="flex-elem  mypuzzles_specification_file-link_all-btn">
              
              <label for="file">
                <div className="flex-elem mypuzzles_specification_file-link_all-btn_file mypuzzles_specification_file-link_all-btn_file_elem">
                  <img className="" src={gallery} alt="select_file" />
                  <span className="mypuzzles_specification_file-link_all-btn_file_text">
                    Add file
                  </span>

                  <input
                    id="file"
                    type="file"
                    onChange={handleChange}
                    onClick={(e) => (e.target.value = null)}
                    className="avatar_input"
                    accept=".pdf,.txt,.docs,.ppt,.xlsx"
                  />
                </div>
              </label>

              <div
                className="flex-elem mypuzzles_specification_file-link_all-btn_file mypuzzles_specification_file-link_all-btn_file_link"
                onClick={() => setIsLinkActive(true)}
              >
                <img className="" src={gallery} alt="select_file" />
                <span className="mypuzzles_specification_file-link_all-btn_file_text">
                  Add Link
                </span>
              </div>
            </div>
          </div>
          {!file && !isLinkActive && (
            <div className="flex mypuzzles_specification_no-link">
              No file or link added
            </div>
          )}
          {file && (
            <div className="mypuzzles_specification_upload-file-container">
              <div className="flex-elem mypuzzles_specification_upload-file">
                <img
                  className="mypuzzles_specification_upload-file_icon"
                  src={docs}
                  alt="docs"
                />
                <span>{file.name}</span>
              </div>
              <img
                className="mypuzzles_specification_upload-file_close"
                src={close}
                alt="close"
                onClick={() => handleCloseDocs()}
              />
            </div>
          )}
          {isLinkActive && (
            <div className="mypuzzles_specification_link-input-container">
              <div className="flex-elem mypuzzles_specification_link-input">
                <img
                  className="mypuzzles_specification_link-input_icon"
                  src={link}
                  alt="link"
                />
                <input
                  type="text"
                  className="mypuzzles_specification_link-input_input"
                  onChange={(e) => setLinkSpecsValue(e.target.value)}
                />
              </div>
              <img
                className="mypuzzles_specification_link-input_close"
                src={close}
                alt="close"
                onClick={() => setIsLinkActive(false)}
              />
            </div>
          )}

          <div className="flex-elem  mypuzzles_product_second_button">
            <button
              className="mypuzzles_product_second_button_quit"
              onClick={() => quit()}
            >
              Quit the creation
            </button>
            <button
              className="mypuzzles_product_second_button_next"
              onClick={() => handleNext()}
            >
              Next step
            </button>
          </div>
        </div>
      </div>
      {console.log("allDataSpecs : ", allDataSpecs)}
    </div>
  );
};

export default Specifications;
