import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ApiService from "../../../../Utils/ApiService";
import routes from "../../../../Routes/routes.json";
import "./FAQAnswer.scss";

const fetchLike = async ({ user, answer }) => {
  const routeFaqLike = `${routes.routes.back_end.iconic_user.faq_likes.url}`;
  const methodFaqLike = `${routes.routes.back_end.iconic_user.faq_likes.method}`;
	ApiService.sendingPutData(
		routeFaqLike,
		methodFaqLike,
		{user, answer},
		"user"
	  )
		.then((result) => {		 
		})
		.catch((err) => {
		  console.log("error : ", err.message);
	});
};

export default function FaqAnswer({ answer, update }) {
  const [liked, setLiked] = useState(false);
  const { user } = useSelector((store) => store.Auth);

  useEffect(() => {
    if (answer.likes && Array.isArray(answer.likes)) {
      const found = answer.likes.find((like) => like.id === (user && user.id));
      setLiked(!!found);
    }
  }, [answer, user]);

  const handleLike = async () => {
    if (user && user.id) {
      setLiked(!liked);
      await fetchLike({
        user: user.id,
        answer: answer.id,
      });
      update();
    }
  };
  console.log("ANSWER", answer);

  return (
    <div className="FAQAnswer">
      <div className="answers-content">
        <div className="username">@{answer.user.username}</div>
        <div className="answer">{answer.content}</div>
      </div>

      <div className="answers-like">
        {user && user.id ? (
          <div className={liked ? "like" : "unlike"} onClick={handleLike}></div>
        ) : (
          <div className="unlike disabled"></div>
        )}
        <div className="like-label">{liked ? "Liked" : "Like"}</div>
      </div>
    </div>
  );
}
