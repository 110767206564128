import React, { useState, useEffect } from "react";
import "./UserItem.scss";
import { useSelector } from "react-redux";

export default function UserItem({ ListUsers, selectAll, onSelectChange }) {
  const links = useSelector((state) => state.User.link);

  let my_links = links && links.filter((link) => link["status"] === "bind");
  const initialUsers = my_links.map((user) => ({
    ...user,
    isChecked: false,
  }));

  const [users, setUsers] = useState(initialUsers);
  const [select, setSelect] = useState([]);

  const handleUserCheck = (id) => {
    const updatedUsers = users.map((user) =>
      user.id === id ? { ...user, isChecked: !user.isChecked } : user
    );
    setUsers(updatedUsers);
  };

  useEffect(() => {
    if (selectAll) {
      setUsers((prevUsers) =>
        prevUsers.map((user) => ({ ...user, isChecked: true }))
      );
    } else {
      setUsers((prevUsers) =>
        prevUsers.map((user) => ({ ...user, isChecked: false }))
      );
    }
  }, [selectAll]);

  useEffect(() => {
    const selectedUsers = users.filter((user) => user.isChecked);
    setSelect(selectedUsers);
    onSelectChange(selectedUsers);
  }, [users]);

  return (
    <div className="users-list">
      {users.map((user) => (
        <div className="user-item-container" key={user?.id}>
          <div className="user-item">
            <div className="profile-pic" />
            <label>{user?.username}</label>
          </div>
          <div
            className={`check-user ${
              user.isChecked ? "checked" : "unchecked"
            }`}
            onClick={() => handleUserCheck(user.id)}
          />
        </div>
      ))}
    </div>
  );
}
