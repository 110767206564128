import profile_picture from "../../Assets/img/User/profile_picture.svg"
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import React from 'react';

const User = ({className="",user}) => {
    const currentUser = useSelector((state) => state.Auth.user);
    const [url, setUrl] = useState(
        "/profile/" + user.username 
    );

    useEffect(() => {
        if (currentUser.username == user.username) {
            setUrl('/myprofile')
        }else {
            setUrl("/profile/" + user.username)
        }
    }, [user]);

    
    return (
        <Link to={url} state={user} className="flex">
            <div className={"user_container  flex flex-ai-c flex-js-fs " + className}>
                <img src={profile_picture} alt="" className="user_container_img"></img>
                <div className="user_container_name">@{user.username}</div>
            </div>
        </Link>
    )
}

export default User