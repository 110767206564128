import React, { useEffect, useMemo, useState, useCallback } from "react";
import NewFaq from "./NewFAQ";
import { Link } from "react-router-dom";
import ApiService from "../../../Utils/ApiService";
import routes from "../../../Routes/routes.json";
import FAQItem from "./FAQItem";
import "./faq.scss";
import NewFAQ from "./NewFAQ";
import Modal from "../../common/Modal";
import { useSelector } from "react-redux";
import { Faqdata } from "./faqData";

const fetchFaqs = async () => {
  const url = `${routes.routes.back_end.iconic_user.faqs.url}`;
  const method = `${routes.routes.back_end.iconic_user.faqs.method}`;
  return await ApiService.fetchApiData(url, method, "user");
};

const FAQ = () => {
  const [isNewFaq, setIsNewFaq] = useState(false);
  const [faqs, setFaqs] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const [toAnswer, setToAnswer] = useState();

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const update = useMemo(() => async () => setFaqs(await fetchFaqs()), []);

  useEffect(() => {
    update();
  }, [isOpen]);
  const user = useSelector((state) => state.Auth);

  const compareLikes = (faq1, faq2) => {
    const likes1 = faq1.answers.reduce(
      (total, { likes }) => likes.length + total,
      0
    );
    const likes2 = faq2.answers.reduce(
      (total, { likes }) => likes.length + total,
      0
    );

    if (likes1 < likes2) {
      return 1;
    } else if (likes1 > likes2) {
      return -1;
    } else {
      return 0;
    }
  };

  return (
    <div className="FAQ">
      {!isNewFaq && (
        <React.Fragment>
          <div className="faq_search flex flex-fd-c flex-ai-c">
            <div className="search_label flex flex-fd-c flex-ai-c">
              <h3>How can we help you ?</h3>
              <span>
                Search for a topic in the FAQ or ask a question in the
                community.
              </span>
            </div>
            <div className="search_bar flex flex-jc-c">
              <div className="search_icon"></div>
              <input
                type="text"
                placeholder="search in FAQ..."
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
          </div>
          <div className="faq_menu">
            <div className="faq_sort">Best trending questions</div>

            {user.isAuthenticated && (
              <div className="add_topic">
                <div className="add_label" onClick={handleClick}>
                  Add topic
                </div>
                <Modal isOpen={isOpen} title="Create Topic" onClose={onClose}>
                  <NewFAQ onClose={onClose} />
                </Modal>
              </div>
            )}
          </div>

          <div className="faq-container">
            {faqs.length > 0 &&
              faqs
                .filter((faq) =>
                  faq.question.toLowerCase().includes(searchValue.toLowerCase())
                )
                .sort(compareLikes)
                .map((faq) => (
                  <FAQItem
                    key={faq.id}
                    faq={faq}
                    update={update}
                    setToAnswer={(id) => console.log(id)}
                  />
                ))}
          </div>
        </React.Fragment>
      )}
      {isNewFaq && <NewFaq setIsNewFaq={setIsNewFaq} />}
    </div>
  );
};

export default FAQ;
