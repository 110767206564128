import React from "react";
import SearchNavbar from "./SearchNavbar";
import NavbarLinks from "./NavbarLinks";

const PopupMenuNavbar = () => {
  return (
    <div className="navbar_burger_menu_content">
        <SearchNavbar Popup={true}/>
        <NavbarLinks Popup={true}/>
    </div>
  );
};

export default PopupMenuNavbar;
