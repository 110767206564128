import configFile from "./config.json";

const APP_ENV = process.env.REACT_APP_ENV || "production"

class config {
  getBackEndUrl(value) {
    switch (APP_ENV) {
      case "production":
        if (value == "user") {
          return `${configFile.url_back_end.production.user}`;
        } else if (value == "transactions") {
          return `${configFile.url_back_end.production.transacions}`;
        } else if (value == "puzzle") {
          return `${configFile.url_back_end.production.puzzle}`;
        } else {
          return `${configFile.url_back_end.production.transacions}`;
        }
      case "staging":
        if (value == "user") {
          return `${configFile.url_back_end.staging.user}`;
        } else if (value == "transactions") {
          return `${configFile.url_back_end.staging.transacions}`;
        } else if (value == "puzzle") {
          return `${configFile.url_back_end.staging.puzzle}`;
        } else {
          return `${configFile.url_back_end.staging.transacions}`;
        }
      case "development":
        if (value == "user") {
          return `${configFile.url_back_end.dev.user}`;
        } else if (value == "transactions") {
          return `${configFile.url_back_end.dev.transacions}`;
        } else if (value == "puzzle") {
          return `${configFile.url_back_end.dev.puzzle}`;
        } else {
          return `${configFile.url_back_end.dev.transacions}`;
        }
      case "testing":
        if (value == "user") {
          return `${configFile.url_back_end.testing.user}`;
        } else if (value == "transactions") {
          return `${configFile.url_back_end.testing.transacions}`;
        } else if (value == "puzzle") {
          return `${configFile.url_back_end.testing.puzzle}`;
        } else {
          return `${configFile.url_back_end.testing.transacions}`;
        }
      case "local":
        if (value == "user") {
          return `${configFile.url_back_end.local.user}`;
        } else if (value == "transactions") {
          return `${configFile.url_back_end.local.transacions}`;
        } else if (value == "puzzle") {
          return `${configFile.url_back_end.local.puzzle}`;
        } else {
          return `${configFile.url_back_end.local.transacions}`;
        }
      default:
        if (value == "user") {
          return `${configFile.url_back_end.production.user}`;
        } else if (value == "transactions") {
          return `${configFile.url_back_end.production.transacions}`;
        } else if (value == "puzzle") {
          return `${configFile.url_back_end.production.puzzle}`;
        } else {
          return `${configFile.url_back_end.production.transacions}`;
        }
    }
  }

  getFrontEndUrl() {
    switch (APP_ENV) {
      case "production": {
        return `${configFile.url_front_end.production.url}`;
      }
      case "staging": {
        return `${configFile.url_front_end.staging.url}`;
      }
      case "development": {
        return `${configFile.url_front_end.dev.url}`;
      }
      case "testing": {
        return `${configFile.url_front_end.testing.url}`;
      }
      case "local": {
        return `${configFile.url_front_end.local.url}`;
      }
    }
  }
}

export default new config();
