/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import logo from "../../../Assets/img/register_logo.svg";
import button_filter from "../../../Assets/img/Market/button_filter.svg";
import button_filter_active from "../../../Assets/img/Market/button_filter_active.svg";
import ApiService from "../../../Utils/ApiService";
import routes from "../../../Routes/routes.json";
import search from "../../../Assets/vectors/Market/search.svg";
import btn_delete from "../../../Assets/vectors/Market/btn_delete.svg";
import valid from "../../../Assets/vectors/Market/valid.svg";
import expand_btn from "../../../Assets/vectors/Market/expand_button.svg";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/scrollbar';
import "../../SwiperHome/SwiperHome.scss";
import { Fragment } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import PuzzleCard from "../../LvxComponents/PuzzleCard";

const MarketBody = () => {
  const [allClasses, setAllClasses] = useState([]);
  const [allPuzzles, setAllPuzzles] = useState([]);
  const [ClassActive, setClassActive] = useState("allPuzzles");
  const [filtersActive, setFiltersActive] = useState(false);
  const [marketSelected, setMarketSelected] = useState("All puzzles");
  const [, setTestSearch] = useState("");
  const [allFilter, setAllFilter] = useState([]);
  const [idSearchActive, setIdSearchActive] = useState("");
  const [, setListDataToDisplay] = useState(allPuzzles);
  const [isAllMarket, setIsAllMarket] = useState(false);
  const [banners, setBanners] = useState([]);

 
  console.log("all puzzles : ", allPuzzles);

  const handleClasses = (class_name) => {
    let activeClass = document.getElementById("class-" + class_name);
    setClassActive(class_name);
    activeClass.style = "background:rgb(155, 142, 255);";
    if (class_name != "allPuzzles") {
      document.getElementById("class-allPuzzles").style =
        "background:rgba(25, 27, 38, 0.5);";
    }
    allClasses.map((value, key) => {
      if (value.name != class_name) {
        document.getElementById("class-" + value.name).style =
          "background:rgba(25, 27, 38, 0.5);";
      }
    });
  };

  const handleFilters = () => {
    let filter_button = document.getElementById("filter_button");
    setFiltersActive(!filtersActive);

    if (!filtersActive) {
      filter_button.src = button_filter_active;
    } else {
      filter_button.src = button_filter;
    }
  };
  const getAllPuzzles = async () => {
    const bannerUrl = `${routes.routes.back_end.iconic_user.banners.url}`;
    const bannerMethod = `${routes.routes.back_end.iconic_user.banners.method}`;
    await ApiService.fetchApiData(bannerUrl, bannerMethod, "user").then(
      (result) => {
        const allPuzzles = result.length > 0 && result.filter((res) =>
          res.tab.includes("allpuzzles")
        ) || [];
        console.log("market ", allPuzzles);
        setBanners(allPuzzles);
      }
    );
  };

  useEffect(() => {
    getAllPuzzles();
  }, []);

  const allPuzzlesCarousel = [
    {
      back: banners[0],
      // description: content
    },
  ];

  useEffect(() => {
    const puzzlesRoute = `${routes.routes.back_end.puzzle.puzzles.url}`;
    const classesRoute = `${routes.routes.back_end.puzzle.classes.url}`;
    const method = `${routes.routes.back_end.puzzle.puzzles.method}`;

    ApiService.fetchApiData(puzzlesRoute, method, "puzzle").then((result) => {
      setAllPuzzles(result.filter((puzzle) => puzzle.state !== "canceled" || puzzle.state !== "to approve"));
      setListDataToDisplay(result.filter((puzzle) => puzzle.state !== "canceled" || puzzle.state !== "to approve"));
    });

    ApiService.fetchApiData(classesRoute, method, "puzzle").then((result) => {
      setAllClasses(result);
    });
  }, []);


  const handleSearchClose = (id) => {
    let activeSearch = document.getElementById(id);
    console.log("activeSearch : ", activeSearch);
    activeSearch.value = "";
    addToFilter(id, activeSearch.value.trim());
  };
  const handlesearch = (e, id) => {
    setIdSearchActive("");
    let activeSearch = document.getElementById(id);
    if (e.target.value !== "") {
      activeSearch.style =
        //"color: rgba(155, 142, 255, 1);background: rgba(23, 25, 35, 1)";
        setIdSearchActive(id);
    }
    setTestSearch(e.target.value);
    addToFilter(id, e.target.value.trim());
  };

  const addToFilter = (key, value) => {
    for (let i = 0; i < allFilter.length; i++) {
      if (allFilter[i]["key"] === key) {
        const tempArray = [...allFilter];
        tempArray[i]["value"] = value;
        setAllFilter(tempArray);
        return;
      }
    }

    let newFilter = {};
    newFilter["key"] = key;
    newFilter["value"] = value;
    setAllFilter([...allFilter, newFilter]);
  };

  const displayFilter = () => {
    return (
      <div className="market_body_right_filters flex flex-js-se">
        {Object.entries(allFilter).map((value, key) => {
          if (typeof value[1] == "string") {
            return (
              <div className="market_body_right_filters_search">
                {idSearchActive != value[0] && (
                  <img
                    draggable="false"
                    src={search}
                    alt="search"
                    className="market_body_right_filters_search_img"
                  />
                )}
                <input
                  className={`market_body_right_filters_search_input  ${
                    idSearchActive == value[0]
                      ? "market_body_right_filters_search_input_searching"
                      : "market_body_right_filters_search_input_default"
                  }`}
                  placeholder={value[0]}
                  id={value[0]}
                  onChange={(e) => {
                    handlesearch(e, value[0]);
                  }}
                />
                {idSearchActive == value[0] && (
                  <img
                    draggable="false"
                    src={btn_delete}
                    alt="search"
                    className="market_body_right_filters_search_delete"
                    onClick={() => handleSearchClose(value[0])}
                  />
                )}
              </div>
            );
          }
        })}
      </div>
    );
  };

  const handleMarketChoice = () => {
    setIsAllMarket(true);
  };

  const renderFisrt = () => {
    setIsAllMarket(false);
  };

  const displayedPuzzles = allPuzzles.filter((puzzle) => {
    if (ClassActive === "allPuzzles" || ClassActive === "allCategories") {
      return (
        marketSelected === "All puzzles" ||
        (marketSelected === "New puzzles" && puzzle.market === "primary" && puzzle.state === "validated") ||
        (marketSelected === "Puzzles market" && puzzle.market === "secondary") ||
        (marketSelected === "Completed" && puzzle.market === "tertiary")
      );
    } else if (ClassActive === "filteredPuzzles") {
      return (
        puzzle.class.includes(ClassActive) &&
        (marketSelected === "All puzzles" ||
          (marketSelected === "New puzzles" && puzzle.market === "primary" && puzzle.state === "validated") ||
          (marketSelected === "Puzzles market" && puzzle.market === "secondary") ||
          (marketSelected === "Completed" && puzzle.market === "tertiary"))
      );
    } else {
      return (
        puzzle.class.includes(ClassActive) &&
        (marketSelected === "All puzzles" ||
          (marketSelected === "New puzzles" && puzzle.market === "primary" && puzzle.state === "validated") ||
          (marketSelected === "Puzzles market" && puzzle.market === "secondary") ||
          (marketSelected === "Completed" && puzzle.market === "tertiary"))
      );
    }
  }).filter(puzzle => puzzle.state !== "canceled" && puzzle.market === "primary" && puzzle.state === "validated" || puzzle.market === "secondary" || puzzle.market === "tertiary");
  

  const sortedPuzzles = displayedPuzzles.sort(
    (a, b) => b.likes.length - a.likes.length
  );

  return (
    <div>
      <div className="market_body flex flex-js-fs">
        <div className="market_body_left flex flex-fd-c flex-js-fs flex-ai-c">
          <div className="market_body_left_img">
            <img src={logo} alt=""></img>
          </div>
          {isAllMarket ? (
            <div className="market_body_left_market flex flex-fd-c flex-js-fs">
              <div className="market_body_left_market_first">
                <span className="market_body_left_market_first_text">
                  {marketSelected}
                </span>
                <img
                  draggable="false"
                  src={expand_btn}
                  alt="expand_btn"
                  className="market_body_left_market_first_img"
                  onClick={() => renderFisrt()}
                />
              </div>
              <div className="market_body_left_market_line-market"></div>
              <div
                className="market_body_left_market_content"
                onClick={() => setMarketSelected("All puzzles")}
              >
                <span className="market_body_left_market_content_text">
                  All puzzles
                </span>
                {marketSelected === "All puzzles" && (
                  <img
                    draggable="false"
                    src={valid}
                    alt="valid"
                    className="market_body_left_market_content_img-valid"
                  />
                )}
              </div>
              <div
                className="market_body_left_market_content"
                onClick={() => setMarketSelected("New puzzles")}
              >
                <span className="market_body_left_market_content_text">
                  New puzzles
                </span>
                {marketSelected === "New puzzles" && (
                  <img
                    draggable="false"
                    src={valid}
                    alt="valid"
                    className="market_body_left_market_content_img-valid"
                  />
                )}
              </div>
              <div
                className="market_body_left_market_content"
                onClick={() => setMarketSelected("Puzzles market")}
              >
                <span className="market_body_left_market_content_text">
                  Puzzles market
                </span>
                {marketSelected === "Puzzles market" && (
                  <img
                    draggable="false"
                    src={valid}
                    alt="valid"
                    className="market_body_left_market_content_img-valid"
                  />
                )}
              </div>
              <div
                className="market_body_left_market_content"
                onClick={() => setMarketSelected("Completed")}
              >
                <span className="market_body_left_market_content_text">
                  Completed
                </span>
                {marketSelected === "Completed" && (
                  <img
                    draggable="false"
                    src={valid}
                    alt="valid"
                    className="market_body_left_market_content_img-valid"
                  />
                )}
              </div>
            </div>
          ) : (
            <div className="market_body_left_expand-market flex-js-c flex-ai-c">
              <span className="market_body_left_expand-market_text">
                {marketSelected}
              </span>
              <img
                draggable="false"
                src={expand_btn}
                alt="expand_btn"
                className="market_body_left_market_first_img"
                onClick={() => handleMarketChoice()}
              />
            </div>
          )}
          <div className="market_body_left_classes">
            <div className="market_body_left_classes_title">Categories</div>
            <div className="market_body_left_classes_content flex flex-fd-c ">
              <div
                className="market_body_left_classes_content_all flex flex-js-fs flex-ai-c"
                onClick={() => handleClasses("allPuzzles")}
                id="class-allPuzzles"
              >
                All categories
              </div>
              {allClasses.map((value, key) => {
                return (
                  <div
                    className="market_body_left_classes_content_class flex flex-js-fs flex-ai-c"
                    onClick={() => handleClasses(value.name)}
                    id={"class-" + value.name}
                  >
                    {value.name}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="market_body_right flex flex-fd-c flex-js-fs">
          <div className="market_body_right_title">All puzzles</div>

          <div className="market_body_right_header flex flex-js-sb">
            {ClassActive}
            <img
              src={button_filter}
              alt=""
              id="filter_button"
              onClick={() => handleFilters()}
            ></img>
          </div>
          {/*filtersActive && primaryFilter(marketSelected)*/}
          {filtersActive && displayFilter()}
          <div className="market_body_right_puzzles ">
            <div className="row">
              {sortedPuzzles.map((puzzle, key) => {
                const url = "/details/" + puzzle.title + "-" + puzzle.reference;
                return (
                  <Link key={key} to={url} state={puzzle}>
                    <PuzzleCard
                      key={puzzle.id}
                      src={puzzle.images[0]}
                      title={puzzle.title}
                      likes={puzzle.likes.length}
                      comments={puzzle.comments.length}
                      state={puzzle}
                    />
                  </Link>
                );
              })}
            </div>
            <div className="my-swiper market-banner">
              <Swiper
                modules={[Navigation, Pagination]}
                className="swiper-truc"
                spaceBetween={0}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
              >
                {banners[0] &&
                  allPuzzlesCarousel.map((car, index) => {
                    return (
                      <Fragment key={banners[index]}>
                        <SwiperSlide
                          style={{
                            backgroundImage: `url(${car.back.img})`,
                          }}
                        >
                          {car.description}
                        </SwiperSlide>
                      </Fragment>
                    );
                  })}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketBody;
