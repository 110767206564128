import notifications_check from "../../../Assets/vectors/notifications_check.svg";
import notifications_activity from "../../../Assets/vectors/notifications_activity.svg";
import notifications_transferin from "../../../Assets/vectors/notifications_transferin.svg";
import notifications_read from "../../../Assets/vectors/notifications_read.svg";
import notification_ckeck from "../../../Assets/vectors/notification_check.svg";
import notification_delete from "../../../Assets/vectors/notification_delete.svg";
import notification_delete_active from "../../../Assets/vectors/notification_delete_active.svg";
import React , { useState, useEffect, useRef } from "react";
import routes from "../../../Routes/routes.json";
import ApiService from "../../../Utils/ApiService";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const Notifications = ({fetchNumberNotifs}) => {
  const user = useSelector((state) => state.Auth.user);
  let currentDate = new Date();
  const [isNotificationHover, setIsNotificationHover] = useState(false);
  const [hovered, setHovered] = useState(null);
  const [isDeleteHovered, setIsDeleteHovered] = useState(false);
  const [allNotification, setAllNotification] = useState(null);
  const [allDataUserToLink, setAllDataUserToLink] = useState({});
  const [commentRedirect, setCommentRedirect] = useState({});
  //const ref = useRef();

  useEffect(() => {
    fetchUserNotification();
  }, []);

  const fetchUserNotification = () => {
    const route = `${routes.routes.back_end.iconic_user.user_notifications.url}`;
    const method = `${routes.routes.back_end.iconic_user.user_notifications.method}`;

    const routeGetUser = `${routes.routes.back_end.iconic_user.get_user.url}`;
    const methodGetUser = `${routes.routes.back_end.iconic_user.get_user.method}`;

    const urlPuzzle = `${routes.routes.back_end.puzzle.puzzle.url}`;
    const methodPuzzle = `${routes.routes.back_end.puzzle.puzzle.get_method}`;

    console.log("url notifs : ", route + user.id);
    ApiService.fetchApiData(route + user.id, method, "user").then((result) => {
      console.log("all notification : ", result);
      let tmpTabLink = {};
      let tmpCommentredirect = {};
      let nbrNotifsNotRead = 0;
      setAllNotification(result);
      result && result.map((value) => {
        if(value.message_read == false) {
          nbrNotifsNotRead+=1;
        }
        if (
          value.description == "New Connection1" ||
          value.description == "New Connection2"
        ) {
          // fetch user with hyperlink
          ApiService.fetchApiData(
            routeGetUser + "/" + value.hyperlink,
            methodGetUser,
            "user"
          ).then((data) => {
            tmpTabLink[value.hyperlink] = data;
          });
        }

        if (
          value.description == "New Mention" ||
          value.description == "New Action" ||
          value.description == "Puzzle Completion"
        ) {
          ApiService.fetchApiData(
            urlPuzzle + value.hyperlink,
            methodPuzzle,
            "puzzle"
          )
            .then((tab) => {
              tmpCommentredirect[value.hyperlink] = tab;
            })
            .catch(() => {
              console.log("error : ");
            });
        }
      });
      fetchNumberNotifs(nbrNotifsNotRead)
      setAllDataUserToLink(tmpTabLink);
      setCommentRedirect(tmpCommentredirect);
    });
  };

  // Notif hover
  const onHover = (value) => {
    console.log("onHover value : ", value);
    setHovered(value);
    setIsNotificationHover(true);
  };

  const outHover = () => {
    setIsNotificationHover(false);
    setHovered(null);
  };

  const handleUpdateNotif = (id, key) => {
    // TODO delete notification
    const route = `${routes.routes.back_end.iconic_user.user_notifications.url}`;
    const method = `${routes.routes.back_end.iconic_user.user_notifications.put_method}`;

    let data = {};
    data[key] = id;
    console.log("data update: ", data);
    ApiService.sendingPutData(route + user.id, method, data, "user").then(
      (result) => {
        console.log("result all notification after updating data", result);
        setAllNotification(result);
      }
    );
  };

  const handleAllNotifRead = () => {
    const route = `${routes.routes.back_end.iconic_user.user_notifications.url}`;
    const method = `${routes.routes.back_end.iconic_user.user_notifications.put_method}`;

    let data = {};
    data["all_notifications_read"] = "all_notifications_read";
    console.log("all_notifications_read: ", data);
    ApiService.sendingPutData(route + user.id, method, data, "user").then(
      (result) => {
        console.log("result after all notifications : ", result);
        setAllNotification(result);
        
      }
    );
  };

  const renderBtnDeleteRead = (value) => {
    return (
      <>
        {isNotificationHover && hovered === value.id && (
          <div
            className={
              value.read
                ? "notifications_today_menu flex flex-js-se flex-ai-c read_width"
                : "notifications_today_menu flex flex-js-se flex-ai-c"
            }
            onMouseEnter={() => onHover(value.id)}
            onMouseLeave={() => outHover()}
          >
            {!value.read && (
              <img
                src={notification_ckeck}
                alt=""
                className=""
                onClick={() =>
                  handleUpdateNotif(value.id, "notification_id_read")
                }
              />
            )}
            <img
              src={
                isDeleteHovered
                  ? notification_delete_active
                  : notification_delete
              }
              alt=""
              className=""
              onMouseEnter={() => setIsDeleteHovered(true)}
              onMouseLeave={() => setIsDeleteHovered(false)}
              onClick={() =>
                handleUpdateNotif(value.id, "notification_id_to_delete")
              }
            />
          </div>
        )}
      </>
    );
  };

  const renderNotif = (value) => {
    return (
      <>
        <div
          className={
            isNotificationHover && hovered === value.id
              ? "notifications_today_content flex-elem flex-ai-c notification_hover_bg"
              : "notifications_today_content flex-elem flex-ai-c"
          }
          onMouseEnter={() => onHover(value.id)}
          onMouseLeave={() => outHover()}
        >
          <img
            src={notifications_activity}
            alt=""
            className="notifications_today_content_img"
          />
          <div className="notifications_today_content_text notifications_today_content_middle flex flex-fd-c">
            <div className="notifications_today_content_text_title">
              {value.description}
            </div>
            <div className="notifications_today_content_text_main">
              {value.message}
            </div>
          </div>
          {!value.message_read && (
            <img
              src={notifications_read}
              alt=""
              className="notifications_today_content_read"
            />
          )}
        </div>
      </>
    );
  };

  const getCurrentDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();
    today = yyyy + "-" + mm + "-" + dd;
    //today = today + " 00:00";
    return today;
  };

  return (
    <div className="notifications flex flex-fd-c flex-js-fs">
      <div className="notifications_header flex flex-ai-c flex-js-sb">
        <div className="notifications_header_title">Notifications</div>
        <div
          className="notifications_header_button flex flex-ai-c flex-js-se"
          onClick={() => handleAllNotifRead()}
        >
          <img src={notifications_check} alt="" />
          Mark all as read
        </div>
      </div>
      <div className="notifications_today">
        <div className="notifications_today_title">Today</div>

        {allNotification &&
          allNotification.map((value) => {
            console.log("new all Notification", allNotification);
            // true to replace with date compare
            //if (true) {
            return (
              <>
                {value.date_create.substr(0, 10) == getCurrentDate() &&
                  value.hyperlink &&
                  (value.description == "New Connection1" ||
                    value.description == "New Connection2") && (
                    <>
                      {renderBtnDeleteRead(value)}
                      <Link
                        to={"/profile/" + value.hyperlink}
                        state={allDataUserToLink[value.hyperlink]}
                      >
                        {renderNotif(value)}
                      </Link>
                    </>
                  )}
                {value.date_create.substr(0, 10) == getCurrentDate() &&
                  value.hyperlink &&
                  value.description == "New Mention" && (
                    <>
                      {renderBtnDeleteRead(value)}
                      <Link
                        to={"/details/" + value.hyperlink}
                        state={commentRedirect[value.hyperlink]}
                      >
                        {renderNotif(value)}
                      </Link>
                    </>
                  )}
                {value.date_create.substr(0, 10) == getCurrentDate() &&
                  value.description == "New Action" && (
                    <>
                      {renderBtnDeleteRead(value)}
                      <Link
                        to={"/details/" + value.hyperlink}
                        state={commentRedirect[value.hyperlink]}
                      >
                        {renderNotif(value)}
                      </Link>
                    </>
                  )}
                {value.date_create.substr(0, 10) == getCurrentDate() &&
                  value.description == "Puzzle Completion" && (
                    <>
                      {renderBtnDeleteRead(value)}
                      <Link
                        to={"/details/" + value.hyperlink}
                        state={commentRedirect[value.hyperlink]}
                      >
                        {renderNotif(value)}
                      </Link>
                    </>
                  )}

                {value.date_create.substr(0, 10) == getCurrentDate() &&
                  !value.hyperlink && (
                    <>
                      {renderBtnDeleteRead(value)}
                      {renderNotif(value)}
                    </>
                  )}

                {value.date_create.substr(0, 10) == getCurrentDate() &&
                  value.hyperlink !='' && value.description != '' &&(
                    <>
                      {renderBtnDeleteRead(value)}
                      {renderNotif(value)}
                    </>
                )}
              </>
            );
          })}
        <div className="notifications_today_title">This week</div>

        {allNotification &&
          allNotification.map((value) => {
            console.log("new all Notification", allNotification);
            // true to replace with date compare
            //if (true) {
            return (
              <>
                {value.date_create.substr(0, 10) != getCurrentDate() &&
                  value.hyperlink &&
                  (value.description == "New Connection1" ||
                    value.description == "New Connection2") && (
                    <>
                      {renderBtnDeleteRead(value)}
                      <Link
                        to={"/profile/" + value.hyperlink}
                        state={allDataUserToLink[value.hyperlink]}
                      >
                        {renderNotif(value)}
                      </Link>
                    </>
                  )}
                {value.date_create.substr(0, 10) != getCurrentDate() &&
                  value.hyperlink &&
                  value.description == "New Mention" && (
                    <>
                      {renderBtnDeleteRead(value)}
                      <Link
                        to={"/details/" + value.hyperlink}
                        state={commentRedirect[value.hyperlink]}
                      >
                        {renderNotif(value)}
                      </Link>
                    </>
                  )}
                {value.date_create.substr(0, 10) != getCurrentDate() &&
                  value.description == "New Action" && (
                    <>
                      {renderBtnDeleteRead(value)}
                      <Link
                        to={"/details/" + value.hyperlink}
                        state={commentRedirect[value.hyperlink]}
                      >
                        {renderNotif(value)}
                      </Link>
                    </>
                  )}
                {value.date_create.substr(0, 10) != getCurrentDate() &&
                  value.description == "Puzzle Completion" && (
                    <>
                      {renderBtnDeleteRead(value)}
                      <Link
                        to={"/details/" + value.hyperlink}
                        state={commentRedirect[value.hyperlink]}
                      >
                        {renderNotif(value)}
                      </Link>
                    </>
                  )}

                {value.date_create.substr(0, 10) != getCurrentDate() &&
                  !value.hyperlink && (
                    <>
                      {renderBtnDeleteRead(value)}
                      {renderNotif(value)}
                    </>
                  )}
              </>
            );
          })}
      </div>
    </div>
  );
};

export default Notifications;
