import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import routes from "../../../Routes/routes.json";
import ApiService from "../../../Utils/ApiService";
import user_avatar from "../../../Assets/img/User/pp.png";
import { requestLink } from "../../../Redux/slices/user";
import Swal from "sweetalert2";
import "./Link.scss";

export default function UserItemLink({  }) {
  const [users, setUsers] = useState([]);
  const userAuth = useSelector((state) => state.Auth.user);
  const [userlinkAuth, setUserlinkAuth] =  useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 5;

  useEffect(() => {
    if (userlinkAuth.length > 0) {
        getUsers(userlinkAuth);
    }
}, [userlinkAuth]);

  const getLinkOfUser = () => {
    const urlUser = `${routes.routes.back_end.iconic_user.getLinks.url}`;
    const methodUser = `${routes.routes.back_end.iconic_user.getLinks.method}`;

    ApiService.fetchApiData(
      urlUser +'/'+ userAuth.id + '/links',
      methodUser,
      "user"
    )
      .then((result) => {
        setUserlinkAuth(result);
      })
      .catch(() => {
        console.log("error : ");
      });
  };

  const getUsers = () => {
    const urlUsers = `${routes.routes.back_end.iconic_user.getUsers.url}`;
    const methodUsers = `${routes.routes.back_end.iconic_user.getUsers.method}`;

    ApiService.fetchApiData(urlUsers, methodUsers, "user")
      .then((result) => {
        const initialUsers = result
            .filter((user) => user.id !== userAuth.id) 
            .filter((user) => !userlinkAuth.some((ulink) => ulink.id === user.id)); 
        setUsers(initialUsers);
      })
      .catch(() => {
        console.log("error : ");
      });
  };

  useEffect(() => {
    getUsers();
  }, []); 

  useEffect(() => {
    getLinkOfUser();
  }, []); 

  const totalPages = Math.ceil(users.length / usersPerPage);
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const sendLink = (username) => {
    const urlLinkRequest = `${routes.routes.back_end.iconic_user.linkrequest.url}`;
    const methodLinkRequest = `${routes.routes.back_end.iconic_user.linkrequest.method}`;

    let data = {};
    data.username = userAuth.username;
    ApiService.sendingExpectPutData(
        urlLinkRequest + "/" + username,
        methodLinkRequest,
      data,
      "user"
    )
      .then((result) => {
        if(result['code'] == 200){
            Swal.fire({
                title: "Link request sent!",
                icon: "success",
                timer: 1500,
                timerProgressBar: true,
                width: 400,
            });
        }
        else {
            Swal.fire({
                title: "Can't send link request",
                icon: "error",
                timer: 1500,
                timerProgressBar: true,
                width: 600,
            });
        }
      })
      .catch((err) => {
        Swal.fire({
            title: "Can't send link request",
            icon: "error",
            timer: 1500,
            timerProgressBar: true,
            width: 600,
        });
      });
  };
    // dispatch(requestLink(userAuth.username, username));
    const isValidUrl = (url) => {
      try {
        new URL(url);
        return true;
      } catch (e) {
        return false;
      }
    };

  return (
    <div>
      <div className="users-list">
        {currentUsers.map((user) => (
          <div className="user-item-container" key={user?.id}>
            <div className="user-item">
            <div className="profile-pic ">
              {user.avatar && isValidUrl(user.avatar) ? (
                <>
                  <img
                    draggable="false"
                    src={user.avatar}
                    className="profile_send_link"
                  />
                </>
              ) : (
                <img
                  draggable="false"
                  src={user_avatar}
                  className="profile_send_link"
                />
              )}
        </div>
              <label>{user?.username}</label>
            </div>
            <button
            className="send-button"
            onClick={() => sendLink(user?.username)}
            >
            Send link
            </button>
          </div>
        ))}
      </div>
      <div className="pagination">
        <button onClick={handlePreviousPage} disabled={currentPage === 1}>
          Prévious
        </button>
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    </div>
  );
}
