import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateUsers } from "../../../Redux/slices/user";
import ButtonProfil from "../../common/ButtonProfil/ButtonProfil";
import SettingButton from "./SettingButton";
import Modal from "../../common/Modal";
import VerificationAccount from "./VerificationAccount";
import password_hidden from "../../../Assets/img/password_hidden.svg";
import ApiService from "../../../Utils/ApiService";
import routes from "../../../Routes/routes.json";
import CircleProgressBar from "../../CircleProgressBar";
import confirm_check from "../../../Assets/img/Account/confirm_check.svg";
import ChangePassword from "./ChangePassword";
import InputSettings from "../../common/InputSettings";
import Swal from "sweetalert2";
import { useQuery } from "react-query";
import { login } from "../../../Redux/slices/auth";
import config from "../../../Config/config.js"

const PersonnalDetails = () => {
  const userConnected = useSelector((state) => state.Auth.user);
  const dispatch = useDispatch();
  
  const [user, setUser] = useState([]);
  const currentPassword = user ? user.password : "";
  const userId = user ? user.id : "";

  const [confirmSuccess, setConfirmSuccess] = useState(false);
  const [progressBar, setProgressBar] = useState(0);
  const [verificationStatus, setVerificationStatus] = useState(
    localStorage.getItem(`verificationStatus_${userId}`) || null
  );

  useEffect(() => {
    localStorage.setItem(`verificationStatus_${userId}`, verificationStatus);
  }, [verificationStatus, userId]);

  useEffect(()=> {
    fetchUser();
  }, [])

  
 const fetchUser = () => {
    const routeGetUser = `${routes.routes.back_end.iconic_user.get_user.url}`;
    const methodGetUser = `${routes.routes.back_end.iconic_user.get_user.method}`;

    ApiService.fetchApiData(
      routeGetUser + "/id/" + userConnected.id,
      methodGetUser,
      "user"
    )
      .then((result) => {
        console.log('resull', result);
        setUser(result);
      })
      .catch((err) => {
        console.log("error : ", err.message);
      });
 }

  const handleSuccess = () => {
    setConfirmSuccess(!confirmSuccess);
  };
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenPassword, setIsOpenPassword] = useState(false);

  const handleClick = useCallback(() => {
    console.log("click");
    setIsOpen(true);
  }, []);

  const hidePassword = (id) => {
    let password_input = document.getElementById(id);
    if (password_input.type === "text") {
      password_input.type = "password";
    } else {
      password_input.type = "text";
    }
  };
  const handleClickPassword = useCallback(() => {
    setIsOpenPassword(true);
  }, []);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onClosePassword = useCallback(() => {
    setIsOpenPassword(false);
  }, []);

  const [activeChoiceMail, setAciveChoiceMail] = useState([]);
  const toggleActiveMail = useMemo(
    () => (key) => {
      if (activeChoiceMail.includes(key)) {
        setAciveChoiceMail([]);
      } else {
        setAciveChoiceMail([key]);
      }
    },
    [activeChoiceMail]
  );

  const [activeChoicePhone, setAciveChoicePhone] = useState([]);
  const toggleActivePhone = useMemo(
    () => (key) => {
      if (activeChoicePhone.includes(key)) {
        setAciveChoicePhone([]);
      } else {
        setAciveChoicePhone([key]);
      }
    },
    [activeChoicePhone]
  );
  const [activeChoiceUsername, setAciveChoiceUsername] = useState([]);
  const toggleActiveUsername = useMemo(
    () => (key) => {
      if (activeChoiceUsername.includes(key)) {
        setAciveChoiceUsername([]);
      } else {
        setAciveChoiceUsername([key]);
      }
    },
    [activeChoiceUsername]
  );
  const [activeChoiceAdress, setAciveChoiceAdress] = useState([]);
  const toggleActiveAdress = useMemo(
    () => (key) => {
      if (activeChoiceAdress.includes(key)) {
        setAciveChoiceAdress([]);
      } else {
        setAciveChoiceAdress([key]);
      }
    },
    [activeChoiceAdress]
  );

  const [email, setEmail] = useState(user.email);
  const [phone, setPhone] = useState(user.phone_number);
  const [username, setUsername] = useState(user.username);
  const [adresse, setAdresse] = useState(user.adresse);

  const usernameQuery = user.username;
  const { isLoading, error, data } = useQuery("repoData", () =>
    fetch(`${config.getBackEndUrl('user')}/user/${usernameQuery}`).then(
      (res) => res.json()
    )
  );
  if (isLoading) return "Loading...";
  if (error) return "An error has occurred: " + error.message;
  console.log("DATA QUERY", data)

  const handleSaveEmail = () => {
    Swal.fire({
      title: "Enter your password",
      html: `
        <input id="password-input" type="password" class="swal2-input" placeholder="Enter your password">
        <button type="button" id="toggle-password" style="position: absolute; right: 120px; top: 90px; background: transparent; border: none; cursor: pointer;">
          <img
            id="toggle-password-img"
            draggable="false"
            src="${password_hidden}"  
            alt="toggle visibility"
            style="width: 20px; height: 20px;"
          />
        </button>
      `,
      showCancelButton: true,
      confirmButtonText: "Verify",
      showLoaderOnConfirm: false,
      background: 'rgb(33, 35, 50)',  
      color: '#fff',  
      preConfirm: () => {
        const password = Swal.getPopup().querySelector('#password-input').value;
        const url = `${routes.routes.back_end.iconic_user.login.url}`;
        const method = `${routes.routes.back_end.iconic_user.login.method}`;
        const data = {
          email: user.email,
          password: password,
        };
  
        return ApiService.sendingExpectPutData(url, method, data, "user")
          .then((response) => {
            if (response?.message === "Logged in") {
              return response;
            } else {
              Swal.showValidationMessage("Incorrect password");
              setTimeout(() => {
                Swal.close(); 
              }, 2000); 
              return Promise.reject("Incorrect password");
            }
          })
          .catch((error) => {
            Swal.showValidationMessage(`${error}`);
            setTimeout(() => {
              Swal.close(); 
            }, 2000); 
            return Promise.reject(error);
          });
      },
      didOpen: () => {
        document.getElementById('toggle-password').addEventListener('click', () => {
         hidePassword('password-input');
       });
     }
    }).then((result) => {
      if (result.isConfirmed) {
        const routeUpdateUser = `${routes.routes.back_end.iconic_user.update_user.url}`;
        const methodUpdateUser = `${routes.routes.back_end.iconic_user.update_user.method}`;
        let data = {};
        data.email = email;

        Swal.showLoading();

        ApiService.postExpectFormData(
          routeUpdateUser + "/" + user.username,
          methodUpdateUser,
          data,
          "user"
        )
          .then((result) => {
            console.log("result after update user: ", result);
            dispatch(updateUsers(result));
            setUser(result);
            setAciveChoiceMail([]);
          })
          .catch((err) => {
            console.log("error: ", err.message);
          })
          .finally(() => {
            Swal.hideLoading(); 
          });
      } else {
        Swal.resetValidationMessage();
      }
    });
  };

  const handleSavePhoneNumber = () => {
    Swal.fire({
      title: "Enter your password",
      html: `
        <input id="password-input" type="password" class="swal2-input" placeholder="Enter your password">
        <button type="button" id="toggle-password" style="position: absolute; right: 120px; top: 90px; background: transparent; border: none; cursor: pointer;">
          <img
            id="toggle-password-img"
            draggable="false"
            src="${password_hidden}"  
            alt="toggle visibility"
            style="width: 20px; height: 20px;"
          />
        </button>
      `,
      showCancelButton: true,
      confirmButtonText: "Verify",
      showLoaderOnConfirm: true,
      background: 'rgb(33, 35, 50)',  
      color: '#fff',  
      preConfirm: () => {
        const password = Swal.getPopup().querySelector('#password-input').value;
        const url = `${routes.routes.back_end.iconic_user.login.url}`;
        const method = `${routes.routes.back_end.iconic_user.login.method}`;
        const data = {
          email: user.email,
          password: password,
        };
  
        return ApiService.sendingExpectPutData(url, method, data, "user")
          .then((response) => {
            if (response?.message === "Logged in") {
              return response;
            } else {
              Swal.showValidationMessage("Incorrect password");
              setTimeout(() => {
                Swal.close(); 
              }, 2000); 
              return Promise.reject("Incorrect password");
            }
          })
          .catch((error) => {
            Swal.showValidationMessage(`${error}`);
            setTimeout(() => {
              Swal.close(); 
            }, 2000); 
            return Promise.reject(error);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
      didOpen: () => {
         // Add event listener to toggle button
        document.getElementById('toggle-password').addEventListener('click', () => {
          hidePassword('password-input');
        });
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const routeUpdateUser = `${routes.routes.back_end.iconic_user.update_user.url}`;
        const methodUpdateUser = `${routes.routes.back_end.iconic_user.update_user.method}`;
        const data = { phone_number: phone };
  
        Swal.showLoading();
  
        ApiService.postExpectFormData(
          `${routeUpdateUser}/${user.username}`,
          methodUpdateUser,
          data,
          "user"
        )
          .then((result) => {
            console.log("Result after updating user:", result);
            dispatch(updateUsers(result));
            setUser(result);
            setAciveChoicePhone([]); // Réinitialiser le choix actif du téléphone
            Swal.fire({
              title: 'Phone number updated!',
              icon: 'success',
              confirmButtonText: 'OK'
            });
          })
          .catch((err) => {
            console.log("Error:", err.message);
            Swal.fire({
              title: 'Error updating phone number',
              text: err.message,
              icon: 'error',
              confirmButtonText: 'OK'
            });
          })
          .finally(() => {
            Swal.hideLoading();
          });
      } else {
        Swal.resetValidationMessage();
      }
    });
  };
  
console.log('userrrrrrrrrrrr', user);
  const handleSaveUsername = () => {
    const routeUpdateUser = `${routes.routes.back_end.iconic_user.update_user.url}`;
    const methodUpdateUser = `${routes.routes.back_end.iconic_user.update_user.method}`;
    let data = {};
    console.log("USERNAME", username);
    data.username = username;
    console.log("data before update: ", data);
    ApiService.postExpectFormData(
      routeUpdateUser + "/" + user.username,
      methodUpdateUser,
      data,
      "user"
    )
      .then((result) => {
        console.log("result after update user : ", result);
        dispatch(updateUsers(result));
        setUser(result);
        setAciveChoiceUsername([]);
      })
      .catch((err) => {
        console.log("error : ", err.message);
      });
  };

  const handleSaveAdress = () => {
    const routeUpdateUser = `${routes.routes.back_end.iconic_user.update_user.url}`;
    const methodUpdateUser = `${routes.routes.back_end.iconic_user.update_user.method}`;
    let data = {};
    data.adresse = adresse;
    console.log("data before update: ", data);
    ApiService.postExpectFormData(
      routeUpdateUser + "/" + user.username,
      methodUpdateUser,
      data,
      "user"
    )
      .then((result) => {
        console.log("result after update user : ", result);
        dispatch(updateUsers(result));
        setUser(result);
        setAciveChoiceAdress([]);
      })
      .catch((err) => {
        console.log("error : ", err.message);
      });
  };

  return (
    <div className="privacy">
      <div className="option-title">Account Info</div>
      <div className="option-wrapper">
        <div className="items">
          <div className="item-select">Profile</div>
          <div className="side">
            <ButtonProfil
              onSubmit={
                data.status_verified === "pending" ||
                data.status_verified === "verified"
                  ? null
                  : handleClick
              }
              color={
                data.status_verified === "pending"
                  ? "dimgray"
                  : data.status_verified === "verified"
                  ? "green !important"
                  : data.status_verified === "verification failed"
                  ? "red"
                  : ""
              }
              disabled={
                data.status_verified === "pending" ||
                data.status_verified === "verified"
              }
            >
              {data.status_verified === "pending"
                ? "Verification in progress"
                : data.status_verified === "verified"
                ? "Profile verified"
                : data.status_verified === "verification failed"
                ? "Verification failed"
                : "Verify my profile"}
            </ButtonProfil>
          </div>

          <Modal isOpen={isOpen} title="Account Verification" onClose={onClose}>
            <VerificationAccount
              onClose={onClose}
              setVerificationStatus={setVerificationStatus}
            />
          </Modal>
        </div>
      </div>
      <div>
        <div className="option-wrapper">
          <div className="items">
            <div className="item-select">E-mail adress</div>
            <div
              className="item-value"
              onClick={() => toggleActiveMail("mail")}
            >
              <span>{user.email}</span>
              <span className="arrow" />
            </div>
          </div>
          {activeChoiceMail.includes("mail") && (
            <div className="choices">
              <div className="choices-listSetting">
                <div className="choice-content">
                  <InputSettings
                    placeholder="Enter your new e-mail"
                    type="text"
                    name="mail"
                    onChange={(e) => {
                      setEmail(e.target.value.trim());
                    }}
                  />
                </div>
                <SettingButton text="Save changes" onSubmit={handleSaveEmail} />
              </div>
            </div>
          )}
        </div>
        <div className="option-wrapper">
          <div className="items">
            <div className="item-select">Phone Number</div>
            <div
              className="item-value"
              onClick={() => toggleActivePhone("phone")}
            >
              <span>{user.phone_number}</span>
              <span className="arrow" />
            </div>
          </div>
          {activeChoicePhone.includes("phone") && (
            <div className="choices">
              <div className="choices-listSetting">
                <div className="choice-content">
                  <InputSettings
                    placeholder="Enter your phone number"
                    type="number"
                    name="phone"
                    onChange={(e) => {
                      setPhone(e.target.value.trim());
                    }}
                  />
                </div>
                <SettingButton
                  text="Save changes"
                  onSubmit={handleSavePhoneNumber}
                />
              </div>
            </div>
          )}
        </div>
        <div className="option-wrapper">
          <div className="items">
            <div className="item-select">Username</div>
            <div
              className="item-value"
              onClick={() => toggleActiveUsername("username")}
            >
              <span>{user.username}</span>
              <span className="arrow" />
            </div>
          </div>
          {activeChoiceUsername.includes("username") && (
            <div className="choices">
              <div className="choices-listSetting">
                <div className="choice-content">
                  <InputSettings
                    placeholder="Enter your new username"
                    type="texte"
                    name="username"
                    onChange={(e) => {
                      setUsername(e.target.value.trim());
                    }}
                  />
                </div>
                <SettingButton
                  text="Save changes"
                  onSubmit={handleSaveUsername}
                />
              </div>
            </div>
          )}
        </div>
        <div className="option-wrapper">
          <div className="items">
            <div className="item-select">Adress</div>
            <div
              className="item-value"
              onClick={() => toggleActiveAdress("adress")}
            >
              <span>{user.adresse}</span>
              <span className="arrow" />
            </div>
          </div>
          {activeChoiceAdress.includes("adress") && (
            <div className="choices">
              <div className="choices-listSetting">
                <div className="choice-content">
                  <InputSettings
                    placeholder="Enter your new Adress"
                    type="texte"
                    name="adress"
                    onChange={(e) => {
                      setAdresse(e.target.value.trim());
                    }}
                  />
                </div>
                <SettingButton
                  text="Save changes"
                  onSubmit={handleSaveAdress}
                />
              </div>
            </div>
          )}
        </div>

        <div className="option-wrapper">
          <div className="items">
            <div className="item-select">Password</div>
            <div className="item-value" onClick={handleClickPassword}>
              <span>Change</span>
              <span className="arrow" />
            </div>
            <Modal
              isOpen={isOpenPassword}
              title="Change your Password"
              onClose={onClosePassword}
            >
              <ChangePassword
                currentPassword={currentPassword}
                onClose={onClosePassword}
              />
            </Modal>
          </div>
        </div>
      </div>
      {confirmSuccess && (
        <div className="flex">
          <div className="exchange_popup flex flex-fd-c flex-js-fs">
            <CircleProgressBar
              trailStrokeColor="#FD55BA"
              strokeColor="#9B8EFF"
              percentage={progressBar}
              innerText="complete"
              radioCircle1={10}
              radioCircle2={12}
              y={19}
              size={38}
              x={19}
              className=""
              withtext={false}
              strokeDasharray={`${progressBar} ${100 - progressBar}`}
              strokeDashoffset={25}
            />
            <img
              src={confirm_check}
              alt=""
              className="transferout_popup_success"
            ></img>
            <div className="transferout_popup_confirm_text flex flex-ai-c">
              {" "}
              Information saved
            </div>
          </div>
          <div id="overlay" onClick={() => handleSuccess()}></div>
        </div>
      )}
    </div>
  );
};

export default PersonnalDetails;
