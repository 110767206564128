import React, { Fragment, useCallback, useState } from 'react'
import Modal from '../../../../common/Modal'
import Unblock from '../Unblock'
import "./BlockedItem.scss"


export default function Blocked() {
  const [unblock, setUnblock] = useState(false)

  const unblockClose = useCallback(() => {
    setUnblock(false);
  }, []);

  const handleModal = useCallback(() => {
    setUnblock(true);
  }, []);



  return (
    <Fragment>
      <div className="Blocked" onClick={handleModal}>
        <div className='profile-pic' />
        <div className='username'>eddy97</div>
      </div>
      <Modal isOpen={unblock} title="Unblock User" onClose={unblockClose}>
        <Unblock onClose={unblockClose} />
      </Modal>
    </Fragment>

  )
}
