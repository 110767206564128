/* eslint-disable no-unreachable */
import React, { useEffect, useCallback, useState } from "react";
import Modal from "../../../../common/Modal";
import Block from "../../Block";
import EditGroup from "../EditGroup";
import "./GroupItem.scss";
import ApiService from "../../../../../Utils/ApiService";
import routes from "../../../../../Routes/routes.json";

export default function GroupItem({ onGroupChange }) {
  const [showEdit, setShowEdit] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [allGroup, setAllGroup] = useState();
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const showEditModal = useCallback((group) => {
    console.log("dataGroup", group);
    setSelectedGroup(group);
    setEditModal(true);
  }, []);

  const onClose = useCallback(() => {
    setEditModal(false);
  }, []);

  useEffect(() => {
    fetchGroup();
  }, [editModal, allGroup]);

  const fetchGroup = () => {
    const urlGroup = `${routes.routes.back_end.user.getGroup.url}`;
    const methodGroup = `${routes.routes.back_end.user.getGroup.method}`;
    ApiService.fetchApiData(urlGroup, methodGroup, "user")
      .then((result) => {
        setAllGroup(result);
      })
      .catch(() => {
        console.log("error : ");
      });
  };

  const handleNextGroup = () => {
    setCurrentGroupIndex((prevIndex) => (prevIndex + 1) % allGroup?.length);
  };
  const getCurrentGroupUsersCount = () => {
    const currentGroup = allGroup?.[currentGroupIndex];
    onGroupChange(allGroup?.[currentGroupIndex]);

    if (currentGroup) {
      return currentGroup.users_group.length;
    }
    return 0;
  };

  return (
    <div className="GroupItem">
      {allGroup?.length > 0 && (
        <div className="group-name">
          {allGroup?.[currentGroupIndex]?.name_group}
        </div>
      )}

      <div
        className="wrapper"
        onMouseEnter={() => setShowEdit(true)}
        onMouseLeave={() => setShowEdit(false)}
      >
        {showEdit ? (
          <div
            className="edit-wrapper"
            onClick={() => showEditModal(allGroup?.[currentGroupIndex])}
          >
            <div className="edit" />
          </div>
        ) : (
          <div className="details">
            <div className="icon" />
            <div className="users-number">{getCurrentGroupUsersCount()}</div>
          </div>
        )}
      </div>
      <Modal isOpen={editModal} title="Create custom group" onClose={onClose}>
        {selectedGroup && (
          <EditGroup
            groupName={selectedGroup?.name_group}
            users={selectedGroup?.users_group}
            id={selectedGroup?.id}
            onCloseEdit={onClose}
          />
        )}
      </Modal>

      {allGroup?.length > 1 && (
        <button className="group-next" onClick={handleNextGroup}>
          Next Group
        </button>
      )}
    </div>
  );
}
