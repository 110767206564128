import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./About.scss";
import ApiService from "../../../Utils/ApiService";
import routes from "../../../Routes/routes.json";
import config from "../../../Config/config";
import { useRef } from "react";

function About() {
  const ref = useRef();
  useEffect(() => {
    fetch(`${config.getBackEndUrl("user")}/static/about.html`)
      .then((response) => response.text())
      .then((text) => {
        ref.current.innerHTML = text;
      });
  }, [ref.current]);

  return <div ref={ref} className="About" />;
}

export default About;
