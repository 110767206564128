/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Puzzle from "./Puzzle";
import { useSelector, useDispatch } from "react-redux";
import routes from "../../../Routes/routes.json";
import ApiService from "../../../Utils/ApiService";
import ProductInformation from "./ProductInformation";
import Media from "./Media";
import Specifications from "./Specifications";
import Confirmation from "./Confirmation";
import puzzle_exemple from "../../../Assets/img/Mypuzzles/puzzle_exemple.png";

const Mypuzzles = () => {
  const [allPuzzles, setAllPuzzles] = useState(null);
  const [allPuzzlesLiked, setAllPuzzlesLiked] = useState(null);
  const [allPuzzlesSold, setAllPuzzlesSold] = useState(null);
  const [valueTabActive, setValueTabActive] = useState("purchased");
  const [isNewPuzzles, setIsNewPuzzles] = useState(false);
  const [isMedia, setIsMedia] = useState(false);
  const [isSpecification, setIsSpecification] = useState(false);
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [alldataStep1, setAlldataStep1] = useState(null);
  const [alldataSpecs, setAlldataSpecs] = useState(null);
  const [currentDataToDisplay, setCurrentDataToDisplay] = useState(null);
  const [allMedia, setAllMedia] = useState(null);
  const [fileSpecification, setFileSpecification] = useState(null);
  const [favoritePuzzles, setFavoritePuzzles] = useState([]);
  const user = useSelector((state) => state.Auth.user);

  useEffect(() => {
    fetchPuzzles();
    fetchPuzzlesLiked();
    fetchPuzzlesSold()
  }, [valueTabActive]);

  useEffect(() => {
    switch (valueTabActive) {
      case "sold":
        setCurrentDataToDisplay(allPuzzlesSold);
        break;
      case "favorites":
        setCurrentDataToDisplay(allPuzzlesLiked);
        break;
      default:
        setCurrentDataToDisplay(allPuzzles);
    }
  }, [valueTabActive, favoritePuzzles, allPuzzles]);

  
  const fetchPuzzles = () => {
    const urlPuzzles = `${routes.routes.back_end.puzzle.gallery.url}`;
    const methodPuzzles = `${routes.routes.back_end.puzzle.gallery.method}`;
    console.log("url : ", urlPuzzles + user.id + "/user");
    ApiService.fetchApiData(
      urlPuzzles + user.id + "/user",
      methodPuzzles,
      "puzzle"
    )
      .then((result) => {
        console.log("result MYPUZZLE : ", result);
        setAllPuzzles(result);
  
      })
      .catch(() => {
        console.log("error : ");
      });
  };

  const fetchPuzzlesLiked = () => {
    const urlPuzzles = `${routes.routes.back_end.puzzle.sold.url}`;
    const methodPuzzles = `${routes.routes.back_end.puzzle.sold.method}`;
    console.log("url : ", urlPuzzles + user?.username + "/getfavorites");
    ApiService.fetchApiData(
      urlPuzzles + user.username + "/getfavorites",
      methodPuzzles,
      "puzzle"
    )
      .then((result) => {
        console.log("result MYPUZZLE : ", result);
        setAllPuzzlesLiked(result);
  
        
      })
      .catch(() => {
        console.log("error : ");
      });
  };
  const fetchPuzzlesSold = () => {
    const urlPuzzles = `${routes.routes.back_end.puzzle.sold.url}`;
    const methodPuzzles = `${routes.routes.back_end.puzzle.sold.method}`;
    console.log("url : ", urlPuzzles + user.id + "/sold");
    ApiService.fetchApiData(
      urlPuzzles + user.id + "/sold",
      methodPuzzles,
      "puzzle"
    )
      .then((result) => {
        console.log("result MYPUZZLE : ", result);
        setAllPuzzlesSold(result);
  
        
      })
      .catch(() => {
        console.log("error : ");
      });
  };
  
  const handleCreatePuzzle = () => {
    setIsNewPuzzles(true);
  };

  const quitCreation = () => {
    setIsNewPuzzles(false);
    setIsSpecification(false);
    setIsMedia(false);
    setIsConfirmation(false);
  };
  const gotoStep2 = () => {
    setIsNewPuzzles(false);
    setIsSpecification(false);
    setIsConfirmation(false);
    setIsMedia(true);
  };
  const gotoStep3 = () => {
    setIsNewPuzzles(false);
    setIsMedia(false);
    setIsConfirmation(false);
    setIsSpecification(true);
  };
  const gotoConfirm = () => {
    setIsNewPuzzles(false);
    setIsMedia(false);
    setIsSpecification(false);
    setIsConfirmation(true);
  };

  const fetchDataStep1 = (data) => {
    setAlldataStep1(data);
  };
  const fetchAllMedia = (data) => {
    setAllMedia(data);
  };
  const fetchDataSpecs = (data) => {
    setAlldataSpecs(data);
  };
  const fetchFileSpecification = (file) => {
    setFileSpecification(file);
  };
  const handleTab = (value) => {
    setValueTabActive(value);

    if (value === "favorites") {
      setCurrentDataToDisplay(favoritePuzzles);
    }
  };
  console.log("Puzzle My Puzzle", currentDataToDisplay);
  console.log("FAVORIS", favoritePuzzles);
  return (
    <div className="mypuzzles flex flex-fd-c flex-js-fs">
      {!isNewPuzzles && !isMedia && !isSpecification && !isConfirmation && (
        <div className="mypuzzles_content">
          <div className="flex flex-fd-c flex-js-fs mypuzzles_content_tab">
            <div className="mypuzzles_content_tab_item">
              <div
                className={`mypuzzles_content_tab_item_content  ${
                  valueTabActive === "purchased"
                    ? "mypuzzles_content_tab_item_active"
                    : "mypuzzles_content_tab_item_inactive"
                }`}
              >
                <span
                  onClick={() => handleTab("purchased")}
                  className="mypuzzles_content_tab_item_content_text"
                >
                  Purchased
                </span>
                {valueTabActive === "purchased" && (
                  <div className="mypuzzles_content_tab_item_content_line-select mypuzzles_content_tab_item_content_line-select_purchased"></div>
                )}
              </div>

              <div
                className={`mypuzzles_content_tab_item_content  ${
                  valueTabActive === "sold"
                    ? "mypuzzles_content_tab_item_active"
                    : "mypuzzles_content_tab_item_inactive"
                }`}
              >
                <span
                  onClick={() => handleTab("sold")}
                  className="mypuzzles_content_tab_item_content_text"
                >
                  Sold
                </span>
                {valueTabActive === "sold" && (
                  <div className="mypuzzles_content_tab_item_content_line-select mypuzzles_content_tab_item_content_line-select_sold"></div>
                )}
              </div>

              <div
                className={`mypuzzles_content_tab_item_content  ${
                  valueTabActive === "favorites"
                    ? "mypuzzles_content_tab_item_active"
                    : "mypuzzles_content_tab_item_inactive"
                }`}
              >
                <span
                  onClick={() => handleTab("favorites")}
                  className="mypuzzles_content_tab_item_content_text"
                >
                  My favorite
                </span>
                {valueTabActive === "favorites" && (
                  <div className="mypuzzles_content_tab_item_content_line-select mypuzzles_content_tab_item_content_line-select_favorite"></div>
                )}
              </div>

            </div>
            <div className="mypuzzles_content_tab_second">
              <div className="mypuzzles_content_tab_second_line"></div>

              <div
                className="mypuzzles_content_tab_second_button"
                onClick={() => handleCreatePuzzle()}
              >
                <button className="mypuzzles_content_tab_second_button_btn">
                  Create puzzle
                </button>
              </div>
            </div>
          </div>
          <div className="flex-js-fs mypuzzles_content_puzzles">
            {currentDataToDisplay && currentDataToDisplay.length > 0 ? (
              currentDataToDisplay.map((value) => {
                return <Puzzle puzzle={value} key={value.id} />;
              })
            ) : (
              <div>No puzzles available.</div>
            )}
          </div>
        </div>
      )}
      {isNewPuzzles && (
        <ProductInformation
          gotoStep2={gotoStep2}
          quitCreation={quitCreation}
          fetchDataStep1={fetchDataStep1}
        />
      )}
      {isMedia && (
        <Media
          gotoStep3={gotoStep3}
          quitCreation={quitCreation}
          fetchAllMedia={fetchAllMedia}
        />
      )}
      {isSpecification && (
        <Specifications
          quitCreation={quitCreation}
          gotoConfirm={gotoConfirm}
          fetchFileSpecification={fetchFileSpecification}
          fetchDataSpecs={fetchDataSpecs}
        />
      )}
      {alldataStep1 && isConfirmation && (
        <Confirmation
          allDataStep1={alldataStep1}
          allMedia={allMedia}
          fileSpecification={fileSpecification}
          allDataSpecs={alldataSpecs}
        />
      )}
    </div>
  );
};

export default Mypuzzles;
