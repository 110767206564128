import React, { useEffect, useState } from "react";
import Wallets from "./Wallets";
import Up from "../../Assets/vectors/currencie_icon_up.svg";
import Down from "../../Assets/vectors/currencie_icon_down.svg";
import Transfer from "../../Assets/vectors/currencie_icon_transfer.svg";
import Icon from "../../Assets/vectors/expand_currency.svg";
import config from "../../Config/config";
import configFile from "../../Config/config.json";
import routes from "../../Routes/routes.json";
import button_active from "../../Assets/vectors/button_active.svg";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getWallets } from "../../Redux/slices/user";
import ApiService from "../../Utils/ApiService";
const Currencies = ({ wallets }) => {
  const [allWallets, setAllWallets] = useState([]);
  const allWalletsObject = []; //useSelector((state) => state.User.wallets);
  const [isCurrenciesActive, setIsCurrenciesActive] = useState(false);
  const [balanceValue, setBalanceValue] = useState(0);
  const [stateWallet, setStateWallet] = useState(true);
  const [currentWallet, setCurrentWallet] = useState(wallets ? wallets[0]:[]);
  const user = useSelector((state) => state.User);
  const dispatch = useDispatch();
  console.log({wallets});

  // useEffect(() => {
  //   //calculateBalance(wallets);
  // }, []);

  useEffect(() => {
    //calculateBalance(wallets);
  }, [stateWallet]);

  // const calculateBalance = (AllWallets) => {
  //   let total = 0;
  //   AllWallets.map((value) => {
  //     const fxRateUrl = `${routes.routes.back_end.user.account.fx_rate.url}`;
  //     const fxRateMethod = `${routes.routes.back_end.user.account.fx_rate.method}`;

  //     ApiService.fetchApiData(
  //       fxRateUrl +
  //         value.amount +
  //         "/" +
  //         currentWallet.currency_code +
  //         "/" +
  //         value.currency_code +
  //         "/" +
  //         user.id,
  //       fxRateMethod,
  //       "transactions"
  //     )
  //       .then((result) => {
  //         console.log("result rate : ", result);
  //         //let balanceTmp = balanceValue;
  //         setBalanceValue(result.total_buy_currency_amount);
  //         //total = total + result.total_buy_currency_amount;
  //       })
  //       .catch(() => {
  //         console.log("error : ");
  //       });
  //     //setBalanceValue(total);
  //   });
  // };
  const handleCurrencies = () => {
    let button = document.getElementById("button_collapse");
    let balance = document.getElementById("account_currencie_balance_div");
    let expand = document.getElementById("account_currencie_balance_expand");
    setIsCurrenciesActive(!isCurrenciesActive);

    if (!isCurrenciesActive) {
      button.src = Icon;
      balance.style = "";
      expand.style = "";
    } else {
      button.src = button_active;
      balance.style = "background: rgba(255, 255, 255, 0.2) ;";
      expand.style = "background: rgba(255, 255, 255, 0.2) ;";
    }
  };

  const handleCurrency = (value) => {
    setCurrentWallet(value);
    setIsCurrenciesActive(!isCurrenciesActive);
    setStateWallet(!stateWallet);
  };

  return (
    <div className="account_currencie">
      <div className="account_currencie_name">Currencies</div>
      <hr className="account_currencie_underscore"></hr>

      <div className="account_currencie_balance">
        <div className="account_currencie_balance_name">Balance</div>
        <div
          className="account_currencie_balance_div flex flex-fd-c"
          id="account_currencie_balance_div"
        >
          <div
            className="account_currencie_balance_amount flex"
            id="account_currencie_balance_amount">
            {/* to adapte */}
            {/* parseFloat(balanceValue).toFixed(2) */}
            {
              !!currentWallet ? <React.Fragment>
                {parseFloat(currentWallet.amount).toFixed(2)}
                {currentWallet.currency_code === "USD" && <span>$</span>}
                {currentWallet.currency_code === "ETH" && <span>ETH</span>}
                {currentWallet.currency_code === "EUR" && <span>*</span>}
                {currentWallet.currency_code === "BTC" && <span>*</span>}
              </React.Fragment> : <span>no Wallets</span>
            }
            <img
              draggable="false"
              src={Icon}
              alt="Transfer in"
              id="button_collapse"
              className=""
              onClick={() => handleCurrencies()}
            />
          </div>
          {!isCurrenciesActive && (
            <div
              className="account_currencie_balance_expand"
              id="account_currencie_balance_expand"
            >
              {wallets && wallets.map((value) => {
                return (
                  <div
                    className="account_currencie_balance_expand_text"
                    onClick={() => handleCurrency(value)}
                  >
                    {value.currency}
                  </div>
                );
              })}
              {/*<div className="account_currencie_balance_expand_text">
                Dollar
              </div>
              <div className="account_currencie_balance_expand_text">Euro</div>
              <div className="account_currencie_balance_expand_text">
                bitcoin
              </div>*/}
            </div>
          )}
        </div>
        <div className="account_currencie_transfer flex">
          <Link to={wallets ? "/transfer_out" : "#"} state={wallets ? wallets[0]:[]}>
            <div className="flex flex-fd-c flex-ai-c">
              <div className="account_currencie_transfer_global">
                <img
                  draggable="false"
                  src={Up}
                  alt="Transfer out"
                  className=""
                />
              </div>
              <div className="account_currencie_transfer_global_name_text">
                Transfer out
              </div>
            </div>
          </Link>
          <Link to={wallets ? "/transfer_in" : "#"} state={wallets ? wallets[0]:[]}>
            <div className="flex flex-fd-c flex-ai-c">
              <div className="account_currencie_transfer_global ">
                <img
                  draggable="false"
                  src={Down}
                  alt="Transfer in"
                  className=""
                />
              </div>
              <div className="account_currencie_transfer_global_name_text">
                Transfer in
              </div>
            </div>
          </Link>
          <div className="flex flex-fd-c flex-ai-c">
            <Link to={wallets ? "/exchange" : "#"} state={wallets ? wallets[0]:[]}>
              <div className="account_currencie_transfer_global">
                <img
                  draggable="false"
                  src={Transfer}
                  alt="Conversion"
                  className=""
                />
              </div>
            </Link>
            <div className="account_currencie_transfer_global_name_text">
              Conversion
            </div>
          </div>
        </div>
      </div>
      <div>
        {!!currentWallet && <Wallets allWallets={wallets} currentWallet={currentWallet} />}
      </div>
      {console.log("wallets : ", wallets)}
    </div>
  );
};

export default Currencies;
