import React, { useState, useEffect } from "react";
import ben from "../../Assets/img/ben.png";
import arrow from "../../Assets/vectors/arrow.svg";
import { useSelector, useDispatch } from "react-redux";
import btn_cancel_order from "../../Assets/vectors/Account/btn_cancel_order.svg";
import ApiService from "../../Utils/ApiService";
import routes from "../../Routes/routes.json";
import { Link } from "react-router-dom";
const Orders = () => {
  //const orders = useSelector((state) => state.User.orders);
  const user = useSelector((state) => state.Auth.user);
  const [boughtOrSold, setBoughtOrSold] = useState(false);
  const [isCancelOrder, setIsCancelOrder] = useState(false);
  const [currentOrder, setCurrentOrder] = useState("");
  const [ordersBought, setOrdersBought] = useState(null);
  const [ordersSold, setOrdersSold] = useState(null);
  const [ordersCurrentData, setOrdersCurrrentData] = useState(null);
  const [allPuzzleLimitOrders, setAllPuzzleLimitOrders] = useState(null);
  /*const [orders, setOrders] = useState([
    {
      type: "buy",
      reference: "ref1",
      puzzle_title: "title 1",
      date: "feb 2022,21",
      quantity: "60",
      total: "20",
      status: "filled",
      code: "ETH",
    },
    {
      type: "sell",
      reference: "ref1",
      puzzle_title: "title 2",
      date: "feb 2022,21",
      quantity: "60",
      total: "20",
      status: "filled",
      code: "USD",
    },
  ]);*/

  const setSold = () => {
    setBoughtOrSold(true);
  };

  useEffect(() => {
    fetchingOrdersData("buy");
    fetchingOrdersData("sell");
    //fetchingAllPuzzleLimitOrder();
    // fetch all puzzle limit order
  }, []);

  useEffect(() => {
    if (!boughtOrSold) {
      setOrdersCurrrentData(ordersBought);
    } else {
      setOrdersCurrrentData(ordersSold);
    }
  }, [boughtOrSold]);

  const fetchingOrdersData = (buy_sell) => {
    const ordersRoute = `${routes.routes.back_end.user.account.list_all_orders_user_my_order.url}`;
    const method = `${routes.routes.back_end.user.account.list_all_orders_user_my_order.method}`;

    console.log("all route : ", ordersRoute + user.id + "/" + buy_sell);
    ApiService.fetchApiData(
      ordersRoute + user.id + "/" + buy_sell,
      method,
      "transactions"
    )
      .then((result) => {
        console.log("result in orders : ", result);
        if (buy_sell == "buy") {
          setOrdersBought(result);
          setOrdersCurrrentData(result);
        } else if (buy_sell == "sell") {
          setOrdersSold(result);
        }
      })
      .catch(() => {
        console.log("error orders : ");
      });
  };

  const fetchingAllPuzzleLimitOrder = () => {
    const puzzleLimitRoute = `${routes.routes.back_end.user.puzzle_limit_orders.url}`;
    const puzzleLimitmethod = `${routes.routes.back_end.user.puzzle_limit_orders.method}`;

    ApiService.fetchApiData(puzzleLimitRoute, puzzleLimitmethod, "transactions")
      .then((result) => {
        console.log("result get puzzle limit : ", result);
        setAllPuzzleLimitOrders(result);
      })
      .catch(() => {
        console.log("error orders : ");
      });
  };

  const setBought = () => {
    setBoughtOrSold(false);
  };

  const handleCancelOrder = (order) => {
    if (order === currentOrder) {
      setCurrentOrder(order);
      setIsCancelOrder(!isCancelOrder);
    } else {
      setCurrentOrder(order);
      setIsCancelOrder(true);
    }
  };
  let referenceToSend = "";
  const handleOrderCancelled = (data) => {
    // checkig right ref to send

    /*allPuzzleLimitOrders.map((value) => {
      // compare puzzle_ref and catch puzzle limit ref
      if (value.puzzle_reference == data.Transaction) {
        referenceToSend = value.reference;
      }
    });*/
    const cancelOrdersRoute = `${routes.routes.back_end.user.account.cancel_puzzle_limit_order.url}`;
    const method = `${routes.routes.back_end.user.account.cancel_puzzle_limit_order.method}`;

    /*if (referenceToSend == "") {
      return;
    }*/
    if (!data["ref_order"]) {
      console.log("ref_order not exist");
      return;
    }
    console.log(
      "cancelOrdersRoute + data.ref_order : ",
      cancelOrdersRoute + data.ref_order
    );
    ApiService.fetchApiData(
      cancelOrdersRoute + data.ref_order, // adding order reference
      method,
      "transactions"
    )
      .then((result) => {
        console.log("result cancel_order : ", result);
        fetchingOrdersData("buy");
        fetchingOrdersData("sell");
        //fetchingAllPuzzleLimitOrder();
        // update Data
      })
      .catch(() => {
        console.log("error cancel orders : ");
      });
  };

  /*const renderBody = () =>{

    // if (orders.length !== 0)
    if (orders)
     if (boughtOrSold) {
        orders.map((value) => {
          if (value.type === "sell") {
            return (
              <div className={isCancelOrder && currentOrder === value.reference ? "account_right_order_tab_content flex flex-fd-c flex-js-fs cancel_order_bg":"account_right_order_tab_content flex flex-fd-c flex-js-fs"}>
                <div className="account_right_order_tab_content_item flex flex-ai-c flex-js-fs" onClick={() => handleCancelOrder(value.reference)}>
                  <div className="account_right_order_tab_header_transactions flex flex-ai-c flex-js-fs">
                    <img draggable="false" src={ben} alt="ben" className="account_right_order_tab_content_item_img"/>
                    {value.puzzle_title}
                  </div>
                  <div className="account_right_order_tab_header_date">
                    {value.date}
                  </div>
                  <div className="account_right_order_tab_header_details">
                    {value.quantity} pieces
                  </div>
                  <div className="account_right_order_tab_header_total">
                    {value.total}
                  </div>
                  <div className="account_right_order_tab_header_status">
                    {value.status}
                  </div>
                </div>
                {isCancelOrder && currentOrder === value.reference && 
                  <div className="btn_cancel_order flex flex-ai-c">
                    <img src={btn_cancel_order} alt="" className="btn_cancel_order_img"></img>
                    Cancel order
                  </div>
                }
              </div>
            );
          }
        })
      }else {
        orders.map((value) => {
          if (value.type === "buy") {
            return (
              <div className={isCancelOrder && currentOrder === value.reference ? "account_right_order_tab_content flex flex-fd-c flex-js-fs cancel_order_bg":"account_right_order_tab_content flex flex-fd-c flex-js-fs"}>
                <div className="account_right_order_tab_content_item flex flex-ai-c flex-js-fs" onClick={() => handleCancelOrder(value.reference)}>
                  <div className="account_right_order_tab_header_transactions flex flex-ai-c flex-js-fs">
                    <img draggable="false" src={ben} alt="ben" className="account_right_order_tab_content_item_img"/>
                    {value.puzzle_title}
                  </div>
                  <div className="account_right_order_tab_header_date">
                    {value.date}
                  </div>
                  <div className="account_right_order_tab_header_details">
                    {value.quantity} pieces
                  </div>
                  <div className="account_right_order_tab_header_total">
                    {value.total}
                  </div>
                  <div className="account_right_order_tab_header_status">
                    {value.status}
                  </div>
                </div>
                {isCancelOrder && currentOrder === value.reference && 
                  <div className="btn_cancel_order flex flex-ai-c">
                    <img src={btn_cancel_order} alt="" className="btn_cancel_order_img"></img>
                    Cancel order
                  </div>
                }
              </div>
            );
          }
        })
      }
      
    else{
      return(
        <p>No orders</p>
      )
    }
  }*/

  const renderBody = () => {
    return (
      <>
        {/*orders.map((value) => {
          return (
            <div
            
              className="account_right_order_tab_content flex flex-fd-c flex-js-fs cancel_order_bg"
            >
              <div
                className="account_right_order_tab_content_item flex flex-ai-c flex-js-fs"
                onClick={() => handleCancelOrder(value.reference)}
              >
                <div className="account_right_order_tab_header_transactions flex flex-ai-c flex-js-fs">
                  <img
                    draggable="false"
                    src={ben}
                    alt="ben"
                    className="account_right_order_tab_content_item_img"
                  />
                  {value.puzzle_title}
                </div>
                <div className="account_right_order_tab_header_date">
                  {value.date}
                </div>
                <div className="account_right_order_tab_header_details">
                  0 / {value.quantity} pieces
                </div>
                <div className="account_right_order_tab_header_total">
                  0 / {value.total} {value.code}
                </div>
                <div className="account_right_order_tab_header_status">
                  {value.status}
                </div>
              </div>

              <div
                className="btn_cancel_order flex flex-ai-c"
                onClick={() => handleOrderCancelled()}
              >
                <img
                  src={btn_cancel_order}
                  alt=""
                  className="btn_cancel_order_img"
                ></img>
                Cancel order
              </div>
            </div>
          );
        })*/}

        {ordersCurrentData &&
          ordersCurrentData
            .slice(0)
            .reverse()
            .map((value, key) => {
              if (key >= 3) {
                return;
              }
              return (
                <div
                  /*className={
                isCancelOrder && currentOrder === value.reference
                  ? "account_right_order_tab_content flex flex-fd-c flex-js-fs cancel_order_bg"
                  : "account_right_order_tab_content flex flex-fd-c flex-js-fs"
              }*/
                  className="account_right_order_tab_content flex flex-fd-c flex-js-fs cancel_order_bg"
                >
                  <div
                    className="account_right_order_tab_content_item flex flex-ai-c flex-js-fs"
                    onClick={() => handleCancelOrder(value.reference)}
                  >
                    <div className="account_right_order_tab_header_transactions flex flex-ai-c flex-js-fs">
                      <img
                        draggable="false"
                        src={ben}
                        alt="ben"
                        className="account_right_order_tab_content_item_img"
                      />
                      {value.Transaction}
                    </div>
                    <div className="account_right_order_tab_header_date">
                      {value.Date.substr(0, 10)}
                      {/*value.date*/}
                    </div>
                    <div className="account_right_order_tab_header_details">
                      {value["Pieces Complete"]} /{" "}
                      {value["Total Pieces Ordered"]} pieces
                    </div>
                    <div className="account_right_order_tab_header_total">
                      {value["Total Price Complete"]} /{" "}
                      {value["Total Order Price"]}
                    </div>
                    <div className="account_right_order_tab_header_status">
                      {value.Status}
                    </div>
                    <div
                      className="btn_cancel_order flex flex-ai-c"
                      onClick={() => handleOrderCancelled(value)}
                    >
                      <img
                        src={btn_cancel_order}
                        alt=""
                        className="btn_cancel_order_img"
                      ></img>
                      Cancel order
                    </div>
                  </div>
                  {/*isCancelOrder && currentOrder === value.reference && (
                <div className="btn_cancel_order flex flex-ai-c">
                  <img
                    src={btn_cancel_order}
                    alt=""
                    className="btn_cancel_order_img"
                  ></img>
                  Cancel order
                </div>
              )*/}

                  {/*<div
                    className="btn_cancel_order flex flex-ai-c"
                    onClick={() => handleOrderCancelled(value)}
                  >
                    <img
                      src={btn_cancel_order}
                      alt=""
                      className="btn_cancel_order_img"
                    ></img>
                    Cancel order
            </div>*/}
                </div>
              );
            })}
      </>
    );
  };

  return (
    <div className="account_right_order">
      <div className="flex flex-js-sb">
        <div>
          <div className="account_right_order_name">Order</div>
          <hr className="account_currencie_underscore"></hr>
        </div>
        <div className="flex account_right_order_switch flex-ai-c">
          <div
            className={
              boughtOrSold
                ? "account_right_order_switch_sold flex"
                : "account_right_order_switch_bought flex"
            }
            onClick={() => setBought()}
          >
            Bought
          </div>
          <div
            className={
              boughtOrSold
                ? "account_right_order_switch_bought flex"
                : "account_right_order_switch_sold flex"
            }
            onClick={() => setSold()}
          >
            Sold
          </div>
        </div>
      </div>
      <div className="account_right_order_tab flex flex-fd-c flex-ai-c">
        {/* <table align="left" className="account_right_order_tab_table" cellSpacing={0} cellPadding={0}>
            <thead className="account_right_order_tab_header">
              <tr className="account_right_order_tab_title">
                <th  className="account_right_order_tab_title_start">Transactions</th>
                <th  className="account_right_order_tab_title_general">Date</th>
                <th  className="account_right_order_tab_title_details">Details</th>
                <th  className="account_right_order_tab_title_general">Total</th>
                <th  className="account_right_order_tab_title_end">Status</th>
              </tr>
            </thead>
            <tbody>
              {renderBody()}
            </tbody>
        </table> */}
        <div className="account_right_order_tab_header flex flex-ai-c flex-js-fs">
          <div className="account_right_order_tab_header_transactions">
            Transactions
          </div>
          <div className="account_right_order_tab_header_date">Date</div>
          <div className="account_right_order_tab_header_details">Progress</div>
          <div className="account_right_order_tab_header_total">Total</div>
          <div className="account_right_order_tab_header_status">Status</div>
        </div>
        {renderBody()}
      </div>

      <Link
        to="/all_transactions"
        state={{
          ordersData: ordersCurrentData,
          isSell: boughtOrSold,
        }}
      >
        <div className="account_right_order_more">
          <span className="account_right_order_more_text">
            See all transactions
          </span>
        </div>
      </Link>
      {allPuzzleLimitOrders &&
        console.log("allPuzzleLimitOrders : ", allPuzzleLimitOrders)}
    </div>
  );
};

export default Orders;
