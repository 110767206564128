import likes from "../../Assets/vectors/Profile/number_likes_profile.svg";
import comments from "../../Assets/vectors/Profile/number_comments_profile.svg";
import link_avatar from "../../Assets/img/Links/link_avatar.svg";
import dot from "../../Assets/vectors/Profile/dot.svg";
import watch from "../../Assets/img/watch.png";
import big_dessert from "../../Assets/img/Links/big_dessert.svg";
import small_dessert1 from "../../Assets/img/Links/small_dessert1.svg";
import small_dessert2 from "../../Assets/img/Links/small_dessert2.svg";
import comments_active from "../../Assets/img/Links/comments_active.svg";
import Comments from "./Comments";
import React, { useEffect, useState } from "react";
import routes from "../../Routes/routes.json";
import ApiService from "../../Utils/ApiService";
import { useSelector, useDispatch } from "react-redux";
import Enter_comment from "../../Assets/vectors/details/Enter_comment.svg";

const Post = ({ post, type, my_links, currentLinkedUserInfo }) => {
  const user = useSelector((state) => state.Auth.user);
  const [commentsActive, setCommentsActive] = useState(true);
  const [currentUserPuzzle, setCurrentUserPuzzle] = useState({});
  const [allUserPuzzle, setAllUserPuzzle] = useState([]);
  const [refActive, setRefActive] = useState(-1);
  const [inputCommentPost, setInputCommentPost] = useState("");

  const [allPost, setAllPost] = useState([
    {
      ref: 0,
      user: "eddy97",
      pictures: [big_dessert, small_dessert1, small_dessert2],
      date_create: "Today at 08:54",
      comments: [
        {
          date_created: "Today at 8H:00",
          message: " last comment",
          user: "bara",
        },
        {
          date_created: "Today at 8H:00",
          message: " 2nd comment",
          user: "doudou",
        },
        {
          date_created: "Today at 9H00",
          message: " first comment",
          user: "bara",
        },
      ],
      likes: [],
    },
    {
      ref: 1,
      user: "doudou23",
      pictures: [big_dessert, small_dessert1, small_dessert2],
      date_create: "Today at 07H:21",
      comments: [
        {
          date_created: "Today at 8H:00",
          message: " last comment",
          user: "bara",
        },
        {
          date_created: "Today at 8H:00",
          message: " 2nd comment",
          user: "doudou",
        },
      ],
      likes: ["moussa"],
    },
  ]);
  const renderImgLike = (img) => {
    return (
      <img
        draggable="false"
        alt=""
        src={img}
        className="profile_gallery_body_card_view_footer_stats_like_icon"
      />
    );
  };

  const handleAddComment = () => {
    let data = {};
    data.date_created = "Today";
    data.message = inputCommentPost;
    data.user = user.username;
    console.log("data : ", data);
    let allPostTmp = allPost;
    allPostTmp.map((value) => {
      if (value.ref == refActive) {
        value.comments.push(data);
      }
    });
    console.log("allPostTmp  : ", allPostTmp);
    setAllPost(allPostTmp);
  };

  const handleLike = () => {
    let allPostTmp = allPost;
    allPostTmp.map((value) => {
      if (value.ref == refActive) {
        if (!value.likes.includes(user.username)) {
          value.likes.push(user.username);
        } else {
          var myIndex = value.likes.indexOf(user.username);
          if (myIndex !== -1) {
            value.likes.splice(myIndex, 1);
          }
        }
      }
    });
    console.log("allPostTmp  : ", allPostTmp);
    setAllPost(allPostTmp);
  };

  /** */

  /** */
  //console.log("myLinks : ", my_links);

  const handleComments = () => {
    let comments_img = document.getElementById("comments_img");
    let comments_number = document.getElementById("comments_number");

    setCommentsActive(!commentsActive);

    if (commentsActive) {
      comments_img.src = comments;
      comments_number.style = "";
    } else {
      comments_img.src = comments_active;
      comments_number.style = "color: rgba(155, 142, 255, 1);";
    }
  };

  const handleRenderComment = (ref) => {
    if (refActive == ref) {
      setRefActive(-1);
    } else {
      setRefActive(ref);
    }
  };

  if (type === "purchase") {
    return (
      <>
        {console.log("currentLinkedUserInfo : ", currentLinkedUserInfo)}
        {currentLinkedUserInfo.map((value) => {
          console.log("value title  : ", value["puzzle"]["title"]);
          return (
            <div className="links_body_right_link flex flex-fd-c flex-js-fs">
              <div className="links_body_right_link_header flex flex-js-fs">
                <img src={link_avatar} alt=""></img>
                <div className="links_body_right_link_header_text flex flex-fd-c">
                  <div className="links_body_right_link_header_text_name">
                    @{value.username}
                  </div>
                  <div className="links_body_right_link_header_text_date">
                    {value.date}
                  </div>
                </div>
              </div>
              <div className="links_body_right_link_content">
                <div className="links_body_right_link_content_text">
                  {value.username} just {value.type} {value.quantity} PPs of a
                  {value["puzzle"]["description"]} for
                  {value.price}
                  {value.currency}
                </div>
                <div className="links_body_right_link_content_puzzle flex flex-js-fs">
                  <img
                    className="links_body_right_link_content_puzzle_img"
                    src={value?.["puzzle"]?.["images"]?.[0]}
                    alt=""
                  ></img>
                  <div className="links_body_right_link_content_puzzle_text flex flex-fd-c">
                    <div className="links_body_right_link_content_puzzle_text_class">
                      {value["puzzle"]["title"]}
                    </div>
                    <div className="links_body_right_link_content_puzzle_text_title">
                      {value?.puzzle["description"]}
                    </div>
                  </div>
                </div>
              </div>
              {/*<Comments active={true} currentLinkedUserPuzzle={value} />*/}
              {/*<Comments active={true} currentPuzzle={currentUserPuzzle} />*/}
            </div>
          );
        })}
      </>
    );
  } else if (type == "pictures") {
    return (
      <>
        {allPost.map((value) => {
          return (
            <div className="links_body_right_link flex flex-fd-c flex-js-fs">
              <div className="links_body_right_link_header flex flex-js-fs">
                <img src={link_avatar} alt=""></img>
                <div className="links_body_right_link_header_text flex flex-fd-c">
                  <div className="links_body_right_link_header_text_name">
                    {value.user}
                  </div>
                  <div className="links_body_right_link_header_text_date">
                    {value.date_create}
                  </div>
                </div>
              </div>
              <div className="links_body_right_link_content flex">
                <img
                  src={big_dessert}
                  alt=""
                  className="links_body_right_link_content_big_img"
                ></img>
                <div className="flex flex-fd-c">
                  <img
                    src={small_dessert1}
                    alt=""
                    className="links_body_right_link_content_small_img"
                  ></img>
                  <img src={small_dessert2} alt=""></img>
                </div>
              </div>
              <div className="links_body_right_link_footer">
                <div
                  className="profile_gallery_body_card_view_footer_stats_like inline"
                  onClick={() => handleLike()}
                >
                  <img
                    draggable="false"
                    alt=""
                    src={likes}
                    className="profile_gallery_body_card_view_footer_stats_like_icon"
                  />
                  <div className="profile_gallery_body_card_view_footer_stats_like_content likes_number">
                    {value.likes.length}
                  </div>
                </div>
                <div
                  className="profile_gallery_body_card_view_footer_stats_comment inline"
                  //onClick={() => handleComments()}
                >
                  <img
                    draggable="false"
                    alt=""
                    src={comments}
                    className="profile_gallery_body_card_view_footer_stats_comment_icon"
                    onClick={() => handleRenderComment(value.ref)}
                  />
                  <div className="profile_gallery_body_card_view_footer_stats_comment_content likes_number">
                    {value.comments.length}
                  </div>
                </div>

                {refActive == value.ref && (
                  <div className="links_body_right_link_comments flex flex-fd-c flex-js-fs">
                    <div className="links_body_left_contacts_requests_title flex-ai-c flex flex-js-fs ">
                      <p>Comments</p> <img src={dot} alt=""></img>{" "}
                      <span> {value.comments.length} </span>
                    </div>
                    <div className="flex flex-ai-c">
                      <img src={link_avatar} alt=""></img>
                      <input
                        type="text"
                        placeholder="Type your comment"
                        className="links_body_right_link_comments_input"
                        onChange={(e) => setInputCommentPost(e.target.value)}
                      ></input>
                      <img
                        src={Enter_comment}
                        alt=""
                        className="links_body_right_link_comments_btn"
                        onClick={() => handleAddComment()}
                      />
                    </div>
                    {value.comments
                      .slice(0)
                      .reverse()
                      .map((value, key) => {
                        if (key > 2) {
                          return;
                        }
                        return (
                          <div className="links_body_right_link_comments_comment flex flex-js-fs">
                            <img src={link_avatar} alt=""></img>
                            <div className="links_body_right_link_comments_comment_content flex flex-fd-c">
                              <div className="links_body_right_link_comments_comment_content_header">
                                @{value.user} <span>today</span>
                              </div>
                              <div className="links_body_right_link_comments_comment_content_text">
                                {value.message}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    <div className="links_body_right_link_comments_more">
                      See more comments
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </>
    );
    return <></>;
  }
};

export default Post;
