import React, { useEffect, useState } from "react";
import Nfts from "./Nfts";
import Orders from "./Orders";
import Currencies from "./Currencies";
import Statements from "./Statements";
import config from "../../Config/config";
import configFile from "../../Config/config.json";
import routes from "../../Routes/routes.json";
import { useSelector, useDispatch } from "react-redux";
import { getWallets, getLink } from "../../Redux/slices/user";
import { Link } from "react-router-dom";
import ApiService from "../../Utils/ApiService";
import Historic from "./Account/Historic";

const Account = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.Auth.user);
  const wallets = useSelector((state) => state.User.wallets);

  useEffect(() => {
    //fetchingOrdersData("buy");
    return () => {
      dispatch(getWallets(user.id));
      console.log(user);
    };
  }, []);

  /*const fetchingOrdersData = (buy_sell) => {
    console.log("in fetching orders : ");
    const ordersRoute = `${routes.routes.back_end.user.account.list_all_orders_user_my_order.url}`;
    const method = `${routes.routes.back_end.user.account.list_all_orders_user_my_order.method}`;

    console.log(
      "all route orders data : ",
      ordersRoute + user.id + "/" + buy_sell
    );
    ApiService.fetchApiData(
      ordersRoute + user.id + "/" + buy_sell,
      method,
      "transactions"
    )
      .then((result) => {
        console.log("result in orders : ", result);
      })
      .catch(() => {
        console.log("error orders : ");
      });
  };*/

  return (
    <div>
      <div className="page_title">
        My account
        <div className="register_header_path flex flex-js-fs">
          <Link to={"/home"}>Home</Link>{" "}
          <div className="register_header_path_gt">{">"}</div>{" "}
          <span>My account</span>
        </div>
      </div>

      <div className="account flex">
        <Currencies wallets={wallets} />
        <div className="account_right">
          {/* <div className="account_right_nfts">
            <Nfts />
          </div> */}
          <Orders />
          {/*<Statements />*/}
          {<Historic />}
        </div>
      </div>
    </div>
  );
};

export default Account;
