import React, { useEffect, useMemo, useState } from "react";
// import NewFaq from "./NewFAQ";
import {Link} from "react-router-dom";
import './Settings.scss'
import Privacy from "../Seetings/Privacy";
import PersonnalDetails from "../Seetings/PersonnalDetails";
import Security from "../Seetings/Security";
import FAQ from '../Faqs'


const Settings = () => {

  const [activePage, setActivePage] = useState("details")

  const handlePage = page => {
    setActivePage(page);
  };

  return (
    <div className="Settings">
      <div className="page-title">
        <p>Settings</p>
        <div className="register-header">
          <Link to={"/home"}>Home</Link>{" "}
          <div className=".register-gt">{">"}</div>{" "}
          <span>Settings</span>
          {/* <div className=".register-gt">{">"}</div>{" "} */}
          {/* <span>
            {activePage === "details" && "Personal details"}
            {activePage === "privacy" && "Privacy Settings"}
            {activePage === "security" && "Security"}
            {activePage === "help" && "Help and Feedback"}
            
          </span> */}
        </div>
      </div>

      <div className="flex flex-fd-c notif-tab">
        <div className="faq_head flex">
          <span onClick={() => handlePage("details")} className={activePage === "details" ? "active" : ""}>Personal Details</span>
          <span onClick={() => handlePage("privacy")} className={activePage === "privacy" ? "active" : ""}>Privacy Setting</span>
          <span onClick={() => handlePage("security")} className={activePage === "security" ? "active" : ""}>Security</span>
          <span onClick={() => handlePage("help")} className={activePage === "help" ? "active" : ""}>Help and Feedback</span>
        </div>
        {activePage === "details" && <PersonnalDetails/>}
        {activePage === "privacy" && <Privacy/>}
        {activePage === "security" && <Security/>}
        {activePage === "help" && <FAQ />}
        

      </div>
    </div>
  );
};



export default Settings;
