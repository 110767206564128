import User from "./User/User"
import watch from "../Assets/img/watch.png"
import dot from "../Assets/vectors/Profile/dot.svg"
import React, { useEffect, useState } from "react";
import routes from "../Routes/routes.json";
import ApiService from "../Utils/ApiService";
import Puzzle from "./Puzzle";
import { useLocation } from "react-router-dom";
import PuzzleCard from "./LvxComponents/PuzzleCard";
import './style.css';

const SearchResult = () => {
    const [allPuzzles, setAllPuzzles] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [usersSearched, setUsersSearched] = useState([]);
    const [puzzlesSearched, setPuzzlesSearched] = useState([]);
    const [filtreActive, setFilterActive] = useState("All")
    const state = useLocation([])
    console.log(state.state)

    useEffect(() => {   
        const puzzlesRoute = `${routes.routes.back_end.puzzle.puzzles.url}`;
        const usersRoute = `${routes.routes.back_end.iconic_user.getUsers.url}`;
        const method = `${routes.routes.back_end.puzzle.puzzles.method}`;
        console.log(puzzlesRoute);
        
        ApiService.fetchApiData(puzzlesRoute, method, "puzzle").then((result) => {
          //processPuzzles(result);
          console.log("all puzzle : ", result);
          setAllPuzzles(result);
        });

        ApiService.fetchApiData(usersRoute, method, "user").then((result) => {
            //processPuzzles(result);
            console.log("all users : ", result);
            setAllUsers(result);
        });

          
    }, []);

    useEffect(() => {
        processElementsSearched(allPuzzles,allUsers)
    }, []);

    const processElementsSearched = (allpuzzles,allusers) => {
        let listPuzzles = [];
        let listUsers = [];
        for (let index = 0; index < allusers.length; index++) {
          const element = allusers[index];
          if (element.username.includes(state.state)){
            listUsers.push(element);
          }
        } 
          for (let index = 0; index < allpuzzles.length; index++) {
            const element = allpuzzles[index];
            if (element.title.includes(state.state)){
              listPuzzles.push(element);
            }
          } 
          setPuzzlesSearched(listPuzzles);
          setUsersSearched(listUsers)
    } 
    const puzzle_res = allPuzzles.filter((puzzle) =>
        puzzle.title.toLowerCase().includes(state.state)
    )
    return (
        <div className="search">
            <div className="page_title">
                Search results
                <div className="register_header_path flex flex-js-fs">
                Home <div className="register_header_path_gt">{">"}</div>{" "}
                <span>Search results</span>{" "}
                </div>
            </div>

            <div className="search_container flex flex-fd-c flex-js-fs">
                <div className="search_container_title">
                    Results for <span className="search_container_title_result">{state.state}</span>
                </div>
                <div className="search_container_categories flex flex-js-fs">
                    <div onClick={() => setFilterActive("All") } className={filtreActive === "All" ? "search_container_categories_categorie flex flex-ai-c categorie_active":"search_container_categories_categorie flex flex-ai-c"}>
                        <div className={ filtreActive === "All" ? "search_container_categories_categorie_name color_purple " : "search_container_categories_categorie_name"}>
                            All
                        </div>
                        <img src={dot} alt="" className="search_container_categories_categorie_dot"></img>
                        <div className="search_container_categories_categorie_count">
                            {usersSearched.length + puzzle_res.length}
                        </div>
                    </div>
                    <div  onClick={() => setFilterActive("Puzzles") } className={filtreActive ==="Puzzles" ? "search_container_categories_categorie flex flex-ai-c categorie_active": "search_container_categories_categorie flex flex-ai-c"}>
                        <div className={filtreActive ==="Puzzles" ? "search_container_categories_categorie_name color_purple": "search_container_categories_categorie_name"}>
                            Puzzles
                        </div>
                        <img src={dot} alt="" className="search_container_categories_categorie_dot"></img>
                        <div className="search_container_categories_categorie_count">
                            {puzzle_res.length}
                        </div>
                    </div>
                    {/* <div  onClick={() => setFilterActive("Upcoming puzzles") } className={filtreActive ==="Upcoming puzzles" ? "search_container_categories_categorie flex flex-ai-c categorie_active":"search_container_categories_categorie flex flex-ai-c"}>
                        <div className={filtreActive ==="Upcoming puzzles" ?"search_container_categories_categorie_name flex flex-ai-c big_text_width color_purple":"search_container_categories_categorie_name flex flex-ai-c big_text_width"}>
                            Upcoming puzzles
                        </div>
                        <img src={dot} alt="" className="search_container_categories_categorie_dot"></img>
                        <div className="search_container_categories_categorie_count">
                            0
                        </div>
                    </div> */}
                    <div onClick={() => setFilterActive("Users") } className={filtreActive === "Users" ? "search_container_categories_categorie flex flex-ai-c categorie_active" : "search_container_categories_categorie flex flex-ai-c"}>
                        <div className={filtreActive === "Users" ? "search_container_categories_categorie_name color_purple" : "search_container_categories_categorie_name"}>
                            Users
                        </div>
                        <img src={dot} alt="" className="search_container_categories_categorie_dot"></img>
                        <div className="search_container_categories_categorie_count">
                            {usersSearched.length}
                        </div>
                    </div>
                </div>
                <div className="row">
                {filtreActive === "All" &&
                
                    allPuzzles.filter((puzzle) =>
                    puzzle.title.toLowerCase().includes(state.state)
                    ).map((puzzle) =>{
                            return (
                                <PuzzleCard 
                                    key={puzzle.id}
                                    to={"/details/" + puzzle.title + "-" + puzzle.reference}
                                    src={puzzle.images[0]}
                                    title={puzzle.title}
                                    likes={puzzle.likes.length}
                                    comments={puzzle.comments.length}
                                />
                            )
                        })
                }

                {filtreActive === "All" &&     
                    usersSearched.map((value,index) =>{
                        return (
                            <User user={value} className={"item_width"}/>
                        )
                    })
                    
                }
                {/* allPuzzles */}
                {/* {filtreActive === "Puzzles" &&
                    puzzlesSearched.map((value,index) =>{
                        return (
                            <Puzzle Search={true} ObjPuzzle={value}/>
                        )
                    })
                } */}
                {filtreActive === "Puzzles" &&
                    allPuzzles.filter((puzzle) =>
                    puzzle.title.toLowerCase().includes(state.state)
                    ).map((puzzle) =>{
                            return (
                                <PuzzleCard 
                                    key={puzzle.id}
                                    to={"/details/" + puzzle.title + "-" + puzzle.reference}
                                    src={puzzle.images[0]}
                                    title={puzzle.title}
                                    likes={puzzle.likes.length}
                                    comments={puzzle.comments.length}
                                />
                            )
                        })
                }

                {filtreActive === "Users" && 
                    usersSearched.map((value,index) =>{
                        return (
                            <User user={value} className={"item_width"}/>
                        )
                    })
                }
                </div>
            </div>
        </div>
    )
}

export default SearchResult