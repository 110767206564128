import React, { useState } from "react";
import BtnSetting from "../Seetings/BtnSetting";
import "./Link.scss";

export const AddLink = ({
  onClose,
  dataLink_fb,
  dataLink_in,
  dataLink_twitter,
}) => {
  const [facebook, setFacebook] = useState(dataLink_fb);
  const [twitter, setTwiter] = useState(dataLink_twitter);
  const [linkedin, setLinkedin] = useState(dataLink_in);

  const handleCancelModal = () => {
    onClose();
  };

  const handleChangeFb = (e) => {
    setFacebook(e);
  };
  const handleChangeTwi = (e) => {
    setTwiter(e);
  };
  const handleChangeLk = (e) => {
    setLinkedin(e);
  };

  const handleValidate = () => {
    const linkData = {
      facebook,
      twitter,
      linkedin,
    };
    onClose(linkData);
  };

  return (
    <>
      <div className="EditGroup">
        <div className="group-name-wrapper">
          <label htmlFor="group-name">Facebook</label>
          <input
            className="title-groupItem"
            type="text"
            id="group-name"
            placeholder="Enter your link facebook"
            onChange={(e) => handleChangeFb(e.target.value)}
            value={facebook}
          />
        </div>
        <div className="group-name-wrapper">
          <label htmlFor="group-name">Twitter </label>
          <input
            className="title-groupItem"
            type="text"
            id="group-name"
            placeholder="Enter your link twitter"
            onChange={(e) => handleChangeTwi(e.target.value)}
            value={twitter}
          />
        </div>
        <div className="group-name-wrapper">
          <label htmlFor="group-name">Linkedin</label>
          <input
            className="title-groupItem"
            type="text"
            id="group-name"
            placeholder="Enter your link linkedin"
            onChange={(e) => handleChangeLk(e.target.value)}
            value={linkedin}
          />
        </div>
      </div>
      <div className="decision-buttons btnLink">
        <BtnSetting
          onClick={handleCancelModal}
          text="Cancel"
          className="cancel"
        />
        <BtnSetting onClick={handleValidate} text="Save" className="confirm" />
      </div>
    </>
  );
};
