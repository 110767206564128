import React from 'react'
import './Footer.scss'



const ButtonFooter = ({ text, className }) => {

  return (
    <div className={`ButtonFooter`}>
      <div className={`${className} icon`}></div>
      <div>
        {text}
      </div>
    </div>
  );
};

export default ButtonFooter;