import { useEffect } from "react";
import user_avatar from "../../Assets/img/Links/user_avatar.svg";
import { useSelector, useDispatch } from "react-redux";
import { getUser } from "../../Redux/slices/user";
import React from 'react';

const User = ({ currentLinkedUserInfo, link }) => {
  console.log("length", currentLinkedUserInfo);
  const user = useSelector((state) => state.User);
  const dispatch = useDispatch();
    dispatch(getUser(user.username));
    const isValidUrl = (url) => {
      try {
        new URL(url);
        return true;
      } catch (e) {
        return false;
      }
    };
  return (
    <div className="links_body_left_user flex flex-fd-c flex-ai-c">
      <div className="links_body_left_user_img flex flex-fd-c flex-ai-c">
      <div className="profile_container_about_left_avatar flex">
              {user.avatar && isValidUrl(user.avatar) ? (
                <>
                  <img
                    draggable="false"
                    src={user.avatar}
                    className="profile_container_about_left_avatar_img"
                  />
                </>
              ) : (
                <img
                  draggable="false"
                  src={user_avatar}
                  className="profile_container_about_left_avatar_img"
                />
              )}
        </div>
              @{user?.username}
      </div>
      <div className="links_body_left_user_stats flex">
        <div className="links_body_left_user_stats_stat flex-ai-c flex flex-fd-c">
          <div className="links_body_left_user_stats_stat_number">
            {link?.length}
          </div>
          <div className="links_body_left_user_stats_links_text">Links</div>
        </div>
        <div className="links_body_left_user_stats_stat flex-ai-c flex flex-fd-c">
          <div className="links_body_left_user_stats_stat_number">{currentLinkedUserInfo?.length}</div>
          <div className="links_body_left_user_stats_links_text">Posts</div>
        </div>
      </div>
    </div>
  );
};

export default User;
