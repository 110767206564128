import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper'
import React from 'react';


import backgroundCarousel from "../../Assets/img/home_carousel_bg.svg";
import backgroundCarousel1 from "../../Assets/img/home_carousel_bg1.svg";
import backgroundCarousel2 from "../../Assets/img/home_carousel_bg2.svg";
import backgroundCarousel3 from "../../Assets/img/home_carousel_bg3.svg";

import ApiService from "../../Utils/ApiService";
import routes from "../../Routes/routes.json";

import red_bottle from "../../Assets/img/red_bottle.png";
import stars from "../../Assets/img/stars.svg";
import notification from "../../Assets/vectors/notification.svg";
import share from "../../Assets/vectors/share.svg";

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/scrollbar';

import './SwiperHome.scss'
import { Fragment } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';



const content = [
  <div className="home_section2 flex-fd-c flex">
    <div className="flex home_section2_first">
      <div className="flex home_section2_first_text to-start flex-fd-c">
        <div>
          <span className="home_section2_first_text_header">Auction</span>
        </div>
        <div className="home_section2_first_text_content">
          <span className="home_section2_first_text_description">
            All the wine you need <br></br>for the parties !
          </span>
        </div>
        
      </div>
      <div className="flex home_section2_first_img">
        <img
          draggable="false"
          src={red_bottle}
          alt="red_bottle"
          className="home_section2_first_img_img"
        />
        <div className="flex home_section2_first_img_text">
          <span className="home_section2_first_img_text_name">Lucia</span>
          <span className="home_section2_first_img_text_quantity">75ml</span>
          <img
            draggable="false"
            src={stars}
            alt="red_bottle"
            className="home_section2_first_img_text_img"
          />
          <span className="home_section2_first_img_text_description">
            Auction at
          </span>
          <span className="home_section2_first_img_text_price">50 €</span>
          {/* <div className="flex flex-js-se home_section2_first_img_text_buttons">
                <img draggable="false" src={left_arrow} alt="red_bottle" />
                <img draggable="false" src={line} alt="red_bottle" />
                <img draggable="false" src={right_arrow} alt="red_bottle" />
              </div> */}
        </div>
      </div>
    </div>
    <div className="flex flex-js-fs home_section2_second">
      <div className="home_section2_second_icon">
        <img
          draggable="false"
          src={notification}
          alt="red_bottle"
          className="home_section2_second_icon_img"
        />
      </div>
      <div className="home_section2_second_share home_section2_second_icon">
        <img
          draggable="false"
          src={share}
          alt="red_bottle"
          className="home_section2_second_icon_share"
        />
      </div>
      <div>
        <button className="home_section2_second_btn">
          <span className="home_section2_second_btn_text">
            Participate in auctions
          </span>
        </button>
      </div>
    </div>
  </div>,
];

const images = [
  {
    original: backgroundCarousel,
    description: content,
  },
  {
    original: backgroundCarousel1,
    description: content,
  },
  {
    original: backgroundCarousel2,
    description: content,
  },
  {
    original: backgroundCarousel3,
    description: content,
  }
];

const SwiperHome = () => {
  const [banners, setBanners] = useState([])


  const imgs = [
    {
      back:banners[0],
      description:content
    }
  ]

    const getHome = async () => {
        const bannerUrl = `${routes.routes.back_end.iconic_user.banners.url}`
        const bannerMethod = `${routes.routes.back_end.iconic_user.banners.method}`
        await ApiService.fetchApiData(bannerUrl, bannerMethod, "user").then((result) => {
            const hommeBanners = result.filter(res => res.tab.includes("homepage"))
            console.log('home ', hommeBanners)
            setBanners(hommeBanners)
        })
    }

    useEffect(() => {
        getHome()
    }, [])

  return (
    <div className='my-swiper home-banner'
    >
      <Swiper
        modules={[Navigation, Pagination]}
        className='swiper-truc'
        spaceBetween={0}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {/* <SwiperSlide
        style={{
          backgroundImage: `url(${images[0].original})`
        }}
        >{images[0].description}</SwiperSlide> */}

        {
          images.map((image, index) => {
            return (
              <Fragment>
                <SwiperSlide key={index}
                  style={{
                    backgroundImage: `url(${image.original})`
                  }}>
                  {image.description}
                </SwiperSlide>
              </Fragment>
            )
          })
        }
        {
          banners[0] && imgs.map((img, index)=>{
            return(
              <Fragment>
                <SwiperSlide key={index}
                  style={{
                    backgroundImage: `url(${img.back.img})`
                  }}>
                  {img.description}
                </SwiperSlide>
              </Fragment>

            )
          })
        }


      </Swiper>
    </div>
  );
};
export default SwiperHome;