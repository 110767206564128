const darkMode = (state = true, action) => {
  switch (action.type) {
    case "DARK_MODE":
      return (state = true);
    case "LIGHT_MODE":
      return (state = false);

    default:
      return (state = state);
  }
};

export default darkMode;
