import React, { useEffect, useState } from "react";
import puzzle from "../../../Assets/img/Profile/puzzle_example.png";
import watch from "../../../Assets/img/Mypuzzles/watch.png";
import likes from "../../../Assets/vectors/Profile/number_likes_profile.svg";
import comments from "../../../Assets/vectors/Profile/number_comments_profile.svg";
import { Link } from "react-router-dom";
import routes from "../../../Routes/routes.json";
import ApiService from "../../../Utils/ApiService";
const Puzzle = ({ puzzle }) => {
  /*const [url, setUrl] = useState(
    "/details/" + puzzle.puzzle_title + "-" + puzzle.reference
  );*/
  const [url, setUrl] = useState("/details_puzzle_user");
  const [initialPuzzle, setInitialPuzzle] = useState(null);

  useEffect(() => {
    fetchPuzzle();
  }, []);

  const fetchPuzzle = () => {
    const urlPuzzle = `${routes.routes.back_end.puzzle.puzzle.url}`;
    const methodPuzzle = `${routes.routes.back_end.puzzle.puzzle.get_method}`;

    ApiService.fetchApiData(
      urlPuzzle + puzzle.reference,
      methodPuzzle,
      "puzzle"
    )
      .then((result) => {
        setInitialPuzzle(result);
      })
      .catch(() => {
        console.log("error : ");
      });
  };

  return (
    <div>
      <Link to={url} state={{ 
        initialPuzzle : initialPuzzle,
        puzzleOfUser : puzzle 
        }}>
        <div className="market_puzzle_margin all-puzzle">
          {initialPuzzle && (
            <>
              {initialPuzzle.images.length > 0 ? (
                <img
                  draggable="false"
                  src={initialPuzzle.images[0]}
                  className="profile_gallery_body_card_view_img market_puzzle_img"
                  alt=""
                />
              ) : (
                <img
                  draggable="false"
                  src={watch}
                  className="profile_gallery_body_card_view_img market_puzzle_img"
                  alt=""
                />
              )}
            </>
          )}

          <div className="home_section3_body_card_view_cover market_puzzle"></div>
          <div className="home_section3_body_card_view_title">
            {puzzle.puzzle_title}
          </div>
          <div className="home_section3_body_card_view_category">
            <div className="profile_gallery_body_card_view_footer_stats inline">
              <div className="profile_gallery_body_card_view_footer_stats_like inline">
                <img
                  draggable="false"
                  alt=""
                  src={likes}
                  className="profile_gallery_body_card_view_footer_stats_like_icon"
                />
                <div className="profile_gallery_body_card_view_footer_stats_like_content">
                  {puzzle.likes.length}
                </div>
              </div>
              <div className="profile_gallery_body_card_view_footer_stats_comment inline">
                <img
                  draggable="false"
                  alt=""
                  src={comments}
                  className="profile_gallery_body_card_view_footer_stats_comment_icon"
                />
                <div className="profile_gallery_body_card_view_footer_stats_comment_content">
                  {puzzle.comments.length}
                </div>
              </div>
            </div>
          </div>
          {console.log("puzzle in : ", puzzle)}
          {initialPuzzle && console.log("initialPuzzle : ", initialPuzzle)}
        </div>
      </Link>
    </div>
  );
};

export default Puzzle;
