import React, { useState } from "react";
import RadioInput from "../Settings/RadioButton";
import "./security.scss";

const Security = () => {
  const [myEmail, setmyEmail] = useState();
  const [mySms, setmySms] = useState();
  const [myFace, setmyFace] = useState();

  const [isLogin, setIsLogin] = useState(true);

  return (
    <div className="privacy">
      <div className="option-title">Two-factor authentication method</div>
      <div className="choices-list">
        <div className="choice-content">
          <div className="space-content">
            <RadioInput
              label={"Email"}
              checked={myEmail}
              setter={setmyEmail}
            />
            <RadioInput
              label={"Sms"}
              checked={mySms}
              setter={setmySms}
            />
            <RadioInput
              label={"Face-id"}
              checked={myFace}
              setter={setmyFace}
            />
          </div>
        </div>
      </div>
      <div className="option-title">Section Privacy Option</div>

      <div className="option-wrapper">
        <div className="items">
          <div className="item-select">Log in</div>
          <div className="side">
            <div className="item-detail">
              {isLogin ? "2Fa actived" : "2Fa actived"}
            </div>
            <label class="switch">
              <input type="checkbox" />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
      </div>

      <div className="option-wrapper">
        <div className="items">
          <div className="item-select">Transfer</div>
          <div className="side">
            <div className="item-detail">
              {isLogin ? "2Fa actived" : "2Fa actived"}
            </div>
            <label class="switch">
              <input type="checkbox" />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
      </div>

      <div className="option-wrapper">
        <div className="items">
          <div className="item-select">Conversion</div>
          <div className="side">
            <div className="item-detail">
              {isLogin ? "2Fa actived" : "2Fa actived"}
            </div>
            <label class="switch">
              <input type="checkbox" />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
      </div>

      <div className="option-wrapper">
        <div className="items">
          <div className="item-select">Transaction</div>
          <div className="side">
            <div className="item-detail">
              {isLogin ? "2Fa actived" : "2Fa actived"}
            </div>
            <label class="switch">
              <input type="checkbox" />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
      </div>

      <div className="option-wrapper">
        <div className="items">
          <div className="item-select">Puzzle Création</div>
          <div className="side">
            <div className="item-detail">
              {isLogin ? "2Fa actived" : "2Fa actived"}
            </div>
            <label class="switch">
              <input type="checkbox" />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
      </div>

      <div className="option-wrapper">
        <div className="items">
          <div className="item-select">Post</div>
          <div className="side">
            <div className="item-detail">
              {isLogin ? "2Fa actived" : "2Fa actived"}
            </div>
            <label class="switch">
              <input type="checkbox" />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Security;
