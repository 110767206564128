import React, { useState } from "react";
import password_hidden from "../../../../Assets/img/password_hidden.svg";
import Button from "../../../common/Button";
import Input from "../../../common/Input";
import ApiService from "../../../../Utils/ApiService";
import routes from "../../../../Routes/routes.json";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import "./password.scss"; 

const ChangePassword = ({ currentPassword, onClose }) => {
  const user = useSelector((state) => state.User);
  console.log("USER PASSWORD UPDATE", user);

  const [actualPassword, setActualPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const hidePassword = (id) => {
    let password_input = document.getElementById(id);
    if (password_input.type === "text") {
      password_input.type = "password";
    } else {
      password_input.type = "text";
    }
  };

  const handleChangePassword = () => {
    if (!actualPassword || !newPassword || !confirmPassword) {
      setError("Please fill in all fields.");
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("New password and confirmation password do not match.");
      return;
    }
    const routeUpdatePassword = `${routes.routes.back_end.iconic_user.password_update.url}`;
    const method = `${routes.routes.back_end.iconic_user.password_update.method}`;
    let data = {};
    data["current_password"] = actualPassword;
    data["new_password"] = newPassword;
    data["confirm_password"] = confirmPassword;
    console.log("data : ", data);
    ApiService.sendingPutData(
      routeUpdatePassword + "/" + user.username,
      method,
      data,
      "user"
    )
      .then((result) => {
        console.log("result", result);
        setActualPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setError(null);
        Swal.fire({
          title: "Password updated successfully",
          text: "SUCCESS",
          icon: "success",
          timer: 1500,
          timerProgressBar: true,
        }).then(() => {
          onClose(); 
        });
      })
      .catch((err) => {
        console.log("error", routeUpdatePassword + "/" + user.username);
      });
  };

  return (
    <form className="contact" onSubmit={handleChangePassword}>
      {error && <div className="error-password">{error}</div>}
        <div className="connection_form_text custom_label_password flex flex-js-fs custom">
        Actual Password
        </div>
        <div className="custom_input_wrapper">
          <input
            type="password"
            label="Actual Password"
            className="connection_form_input"
            placeholder="Enter your actual password"
            id="actual_password_input"
            value={actualPassword || currentPassword}
            onChange={(e) => setActualPassword(e.target.value.trim())}
          ></input>
          <img
            draggable="false"
            src={password_hidden}
            alt=""
            className="connection_form_hide"
            onClick={() => hidePassword("actual_password_input")}
          />
        </div>
        
      <div className="connection_form_text custom_label_password flex flex-js-fs">
        New Password
      </div>
      <div className="custom_input_wrapper">
        <input
          type="password"
          label="New Password"
          className="connection_form_input"
          placeholder="Enter your new password"
          id="new_password_input"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value.trim())}
        ></input>
        <img
          draggable="false"
          src={password_hidden}
          alt=""
          className="connection_form_hide"
          onClick={() => hidePassword("new_password_input")}
        />
      </div>
    
      <div className="connection_form_text custom_label_password flex flex-js-fs">
        New Password confirm
      </div>
      <div className="custom_input_wrapper">
        <input
          type="password"
          label="New Password Confirm"
          className="connection_form_input"
          placeholder="Enter again your new password"
          id="confirm_password_input"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value.trim())}
        ></input>
        <img
          draggable="false"
          src={password_hidden}
          alt=""
          className="connection_form_hide"
          onClick={() => hidePassword("confirm_password_input")}
        />
      </div>
      <div className="submit-btn">
        <Button onSubmit={() => handleChangePassword()}>Save Password</Button>
      </div>
    </form>
  );
};

export default ChangePassword;
