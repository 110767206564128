import React, { useEffect, useState } from "react";
import puzzle_exemple from "../../../Assets/img/Mypuzzles/puzzle_exemple.png";
import gallery from "../../../Assets/vectors/Mypuzzles/gallery.svg";
import close from "../../../Assets/vectors/Mypuzzles/close.svg";

const Media = ({ gotoStep3, quitCreation, fetchAllMedia }) => {
  const [file, setFile] = useState(null);
  const [allFile, setAllFile] = useState([]);
  function handleChange(event) {
    let data = allFile;
    if (data.length >= 3) {
      return;
    }
    if (event.target.files.length > 0) {
      //const file = URL.createObjectURL(event.target.files[0]);
      const file = event.target.files[0];
      //setFile(file);
      data = [...allFile, file];
      setAllFile(data);
      //console.log("file : ", file);
      console.log("data.length : ", data.length);
    }
  }

  const handleNext = () => {
    if (allFile.length == 0) {
      return;
    }
    fetchAllMedia(allFile);
    gotoStep3();
  };
  const quit = () => {
    quitCreation();
  };

  const handleClose = (index) => {
    console.log("index : ", index);
    let data = allFile;
    data.splice(index, 1);
    setAllFile([...data]);
  };

  return (
    <div className="mypuzzles_media">
      <div className="mypuzzles_product">
        <div className="flex-elem flex-fd-c mypuzzles_product_first">
          <div className="">
            <span className="mypuzzles_product_second_product_information">
              Create a new puzzle
            </span>
          </div>
          <img className="mypuzzles_product_first_img" src={puzzle_exemple} />
        </div>
        <div className="flex-fd-c mypuzzles_product_second">
          <div className="mypuzzles_product_second_product">
            <span className="mypuzzles_product_second_product_information">
              Media
            </span>
            <span className="mypuzzles_product_second_product_step">
              step 2 of 3
            </span>
          </div>
          <div className="flex-elem mypuzzles_media_all-btn">
            <>
              <label for="file">
                <div className="flex-elem mypuzzles_media_all-btn_pics">
                  <img className="" src={gallery} alt="select_file" />
                  <span className="mypuzzles_media_all-btn_pics_text">
                    Add pics
                  </span>

                  <input
                    id="file"
                    type="file"
                    onChange={handleChange}
                    onClick={(e) => (e.target.value = null)}
                    className="avatar_input"
                    accept="image/*"
                  />
                </div>
              </label>
            </>
          </div>
          {allFile.length == 0 ? (
            <div className="flex- mypuzzles_media_no-media">No media added</div>
          ) : (
            <>
              <div className="flex-elem flex-fd-c mypuzzles_media_uploaded-img">
                <div className="mypuzzles_media_uploaded-img_text">Images</div>
                {allFile.length > 0 && (
                  <div className="flex-elem mypuzzles_media_uploaded-img_all">
                    {allFile.map((value, index) => {
                      return (
                        <>
                          <div className="flex-elem mypuzzles_media_uploaded-img_all_content">
                            <img
                              className="mypuzzles_media_uploaded-img_all_item"
                              src={URL.createObjectURL(value)}
                              alt={value.name}
                            />
                            <img
                              className="mypuzzles_media_uploaded-img_all_close"
                              src={close}
                              alt="close"
                              onClick={() => handleClose(index)}
                            />
                          </div>
                        </>
                      );
                    })}
                  </div>
                )}
              </div>
            </>
          )}

          <div className="flex-elem  mypuzzles_product_second_button">
            <button
              className="mypuzzles_product_second_button_quit"
              onClick={() => quit()}
            >
              Quit the creation
            </button>
            <button
              className="mypuzzles_product_second_button_next"
              onClick={() => handleNext()}
            >
              Next step
            </button>
          </div>
        </div>
      </div>
      {console.log("allFile : ", allFile)}
    </div>
  );
};

export default Media;
