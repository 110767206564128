import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import routes from "../../../Routes/routes.json";
import ApiService from "../../../Utils/ApiService";
import { useSelector } from "react-redux";

const MyWallet = () => {
  const user = useSelector((state) => state.Auth.user);
  const [allHistoric, setAllHistoric] = useState(null);

  useEffect(() => {
    fetchHistoric();
  }, []);

  const fetchHistoric = () => {
    const historicRoute =
      routes.routes.back_end.user.account.historic.url + user.id;
    const method = `${routes.routes.back_end.user.account.historic.method}`;

    console.log("historicRoute : ", historicRoute);
    ApiService.fetchApiData(historicRoute, method, "transactions").then(
      (result) => {
        console.log("result historic : ", result);
        setAllHistoric(result);
      }
    );
  };

  return (
    <div className="my_wallet">
      <div className="all-transactions flex flex-fd-c flex-js-fs">
        <div className="page_title page_title_transferin">
          My wallet
          <div className="register_header_path flex flex-js-fs">
            <Link to="/home">Home</Link>{" "}
            <div className="register_header_path_gt">{">"}</div>{" "}
            <Link to="/account">
              <span>Account</span>
            </Link>{" "}
            <div className="register_header_path_gt">{">"}</div>{" "}
            <span>My wallet</span>
          </div>
        </div>
      </div>

      <div className="my_wallet_container">
        <div className="my_wallet_container_content">
          <div className="my_wallet_container_content_tab">
            <div className="flex-elem my_wallet_container_content_tab_row">
              <div className="my_wallet_container_content_tab_row_currency">
                Currency
              </div>
              <div className="my_wallet_container_content_tab_row_amount">
                Amount
              </div>
              <div className="my_wallet_container_content_tab_row_amount">
                Actions
              </div>
              <div className="my_wallet_container_content_tab_row_amount">
                Date
              </div>
            </div>
          </div>

          {allHistoric ? (
            allHistoric.map((value, index) => (
              <React.Fragment key={index}>
                <div className="flex-elem flex-fd-c my_wallet_container_content_elems">
                  <div className="flex-elem my_wallet_container_content_elems_row">
                    <div className="flex-elem my_wallet_container_content_elems_row_currency">
                      {value.currency}
                    </div>
                    <div className="flex-elem my_wallet_container_content_elems_row_amount">
                      {parseFloat(value.amount).toFixed(2)}
                    </div>
                    <div className="flex-elem my_wallet_container_content_elems_row_amount">
                      {value.action}
                    </div>
                    <div className="flex-elem my_wallet_container_content_elems_row_amount">
                      {value.date.substr(0, 10)}
                    </div>
                  </div>
                </div>
                <div
                  className="my_wallet_container_content_line"
                  key={"line-" + index}
                ></div>
              </React.Fragment>
            ))
          ) : (
            <div>No transaction history available.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyWallet;
