import { createSlice } from "@reduxjs/toolkit";
import ApiService from "../../Utils/ApiService";
import routes from "../../Routes/routes.json";

// ----------------------------------------------------------------------

const initialState = {
  id: "",
  avatar: "",
  username: "",
  email: "",
  phone_number: "",
  adresse: "",
  birth_date: "",
  about_me: "",
  link_in: "",
  link_fb: "",
  link_twitter: "",
  link: [],
  wallets: [],
  orders: [],
  puzzle: [],
  statement: [],
  password: "",
  photo_prise: "",
  photo_upload: "", 
  status_verified: "", 
  first_name: "", 
  last_name: "", 
  account_type: "",
  visit_my_profile: "",
  tag_me: "",
  message_me: "",
  see_my_activity: ""
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getLinksSuccess(state, action) {
      state.link = action.payload;
    },

    getUserSuccess(state, action) {
      state.id = action.payload.id;
      state.avatar = action.payload.avatar;
      state.username = action.payload.username;
      state.email = action.payload.email;
      state.phone_number = action.payload.phone_number;
      state.adresse = action.payload.adresse;
      state.birth_date = action.payload.birth_date;
      state.about_me = action.payload.about_me;
      state.link = action.payload.link;
      state.link_in = action.payload.link_in;
      state.link_fb = action.payload.link_fb;
      state.link_twitter = action.payload.link_twitter;
      state.password = action.payload.password;
      state.photo_prise = action.payload.photo_prise;
      state.photo_upload = action.payload.photo_upload;
      state.status_verified = action.payload.status_verified;
      state.first_name = action.payload.first_name;
      state.last_name = action.payload.last_name;
      state.account_type = action.payload.account_type;
      state.visit_my_profile = action.payload.privacy.visit_my_profile;
      state.tag_me = action.payload.privacy.tag_me;
      state.message_me = action.payload.privacy.message_me;
      state.see_my_activity = action.payload.privacy.see_my_activity;
    },

    getWalletsSuccess(state, action) {
      state.wallets = action.payload;
    },

    getOrdersSucess(state, action) {
      state.orders = action.payload;
    },

    getStatementsSuccess(state, action) {
      state.statement = action.payload;
    },
  },
});
export const {getUserSuccess} = slice.actions
// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getLink(username) {
  return async (dispatch) => {
    const route =
      `${routes.routes.back_end.iconic_user.getLinks.url}/` +
      username +
      "/links";
    const method = `${routes.routes.back_end.iconic_user.getLinks.method}`;
    console.log("lingk");
    ApiService.fetchApiData(route, method, "user")
      .then((result) => {
        const links = result;
        dispatch(slice.actions.getLinksSuccess(links));
      })
      .catch((err) => {
        console.log("error : ", err.message);
      });
  };
}

export function acceptLink(user_username, target_username) {
  return async (dispatch) => {
    const routeAccept = `${routes.routes.back_end.iconic_user.accept_link.url}`;
    const methodAccept = `${routes.routes.back_end.iconic_user.accept_link.method}`;

    ApiService.sendingPutData(
      routeAccept,
      methodAccept,
      { user: user_username, target: target_username },
      "user"
    )
      .then((result) => {
        console.log("accept link result");
        const links = result.link;
        console.log("new links : ", links);
        dispatch(slice.actions.getLinksSuccess(links));
        //dispatch(slice.actions.getLinksSuccess([...links]));
      })
      .catch((err) => {
        console.log("error : ", err.message);
      });
  };
}

export function refuseLink(user_username, target_username) {
  return async (dispatch) => {
    const routeRefuse = `${routes.routes.back_end.iconic_user.refuse_link.url}`;
    const methodRefuse = `${routes.routes.back_end.iconic_user.refuse_link.method}`;

    ApiService.sendingPutData(
      routeRefuse,
      methodRefuse,
      { user: user_username, target: target_username },
      "user"
    )
      .then((result) => {
        const links = result.link;
        dispatch(slice.actions.getLinksSuccess(links));
      })
      .catch((err) => {
        console.log("error : ", err.message);
      });
  };
}

export function requestLink(user_username, target_username) {
  return async (dispatch) => {
    const routeRequest = `${routes.routes.back_end.iconic_user.linkrequest.url}`;
    const methodRequest = `${routes.routes.back_end.iconic_user.linkrequest.method}`;

    console.log(user_username);
    ApiService.sendingPutData(
      routeRequest + "/" + target_username,
      methodRequest,
      { username: user_username },
      "user"
    )
      .then((result) => {
        const links = result.link;
        console.log(links);
        dispatch(slice.actions.getLinksSuccess(links));
      })
      .catch((err) => {
        console.log("error : ", err.message);
      });
  };
}

export function getUser(username) {
  const routeGetUser = `${routes.routes.back_end.iconic_user.get_user.url}`;
  const methodGetUser = `${routes.routes.back_end.iconic_user.get_user.method}`;

  return async (dispatch) => {
    ApiService.fetchApiData(
      routeGetUser + "/" + username,
      methodGetUser,
      "user"
    )
      .then((result) => {
        console.log('resssss',result);
        const user = result;
        dispatch(slice.actions.getUserSuccess(user));
      })
      .catch((err) => {
        console.log("error : ", err.message);
      });
  };
}

export function updateUsers(update_user) {
  return async (dispatch) => {
    dispatch(slice.actions.getUserSuccess(update_user));
  };
}

export function getWallets(id) {
  return async (dispatch) => {
    const methodWallets = `${routes.routes.back_end.user.account.get_wallets.method}`;

    ApiService.fetchApiData("/wallets/" + id, methodWallets, "transactions")
      .then((result) => {
        const wallets = result;
        dispatch(slice.actions.getWalletsSuccess(wallets));
      })
      .catch((err) => {
        console.log("error : ", err.message);
      });
  };
}
