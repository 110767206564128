import React, { useState, useCallback } from "react";
import UserItem from "../UserItem";
import "./Block.scss";
import BtnSetting from "../BtnSetting";

export default function Block({ onClose }) {
  const [, /*selectedUsers*/ setSelectedUsers] = useState([]);
  const [item, setItem] = useState();
  console.log("user selectionné", item);

  const handleSelectChange = (select) => {
    setItem(select);
    // onItemSelected(select);
  };

  const handleCancelModal = () => {
    onClose();
  };

  return (
    <div className="Block">
      <div className="block-title">Select user from your link</div>
      <div className="users-list">
        <UserItem
          ListUsers={setSelectedUsers}
          onSelectChange={handleSelectChange}
         />
      </div>
      <div className="decision-buttons">
        <BtnSetting text="Cancel" className="cancel" onClick={handleCancelModal} />
        <BtnSetting text="Block" className="confirm" />
      </div>
    </div>
  );
}
