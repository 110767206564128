import React, { useState, useCallback } from "react";
import ApiService from "../../../../Utils/ApiService";
import routes from "../../../../Routes/routes.json";
import { useSelector } from "react-redux";
import Input from "../../../common/Input";
import "./NewFAQ.scss";

const NewFAQ = ({ onClose }) => {
  // const [isNewNotification, setIsNewNotification] = useState(false)
  const [question, setQuestion] = useState("");
  const [title, setTitle] = useState("");
  const { user } = useSelector((store) => store.Auth);
  
  const store = useSelector((store) => store);
  console.log("store", { store });

  const createFaq = () => {
    let data = {
      question,
      owner: user.id,
    };
    const route = `${routes.routes.back_end.iconic_user.faq.url}`;
    const method = `${routes.routes.back_end.iconic_user.faq.method}`;
    ApiService.sendingPutData(route, method, data, "user").then((result) => {
      console.log("data ", data);
      onClose();
    });
  };

  return (
    <div className="NewFAQ">
      <div className="form">
        <div className="container-input">
          <Input
            label="Title"
            placeholder="Enter the title"
            type="text"
            name="name"
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
          <Input
            label="Texte"
            placeholder="Type texte here"
            type="textarea"
            name="content"
            onChange={(e) => {
              setQuestion(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="buttons">
        <button className="button" onClick={(e) => createFaq()}>
          Save Topic
        </button>
      </div>
    </div>
  );
};

export default NewFAQ;
