import { createSlice } from "@reduxjs/toolkit";
import ApiService from "../../Utils/ApiService";
import routes from "../../Routes/routes.json";
import { getLink } from "./user";
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  isAdmin: false,
  error: 0,
  message: "",
  user: {},
  initialized: false,
  link: [],
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // LOGIN

    loginSuccess(state, action) {
      //console.log(action.payload.user)
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.isAdmin = action.payload.user.is_admin;
      state.initialized = true;
    },
    getLinksSuccess(state, action) {
      state.user.link = action.payload;
    },
    logoutSuccess(state) {
      state.isAuthenticated = false;
      state.user = null;
      state.initialized = true;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function login({ email, password }) {
  return async (dispatch) => {
    const route = `${routes.routes.back_end.iconic_user.login.url}`;
    const method = `${routes.routes.back_end.iconic_user.login.method}`;

    ApiService.sendingPutDataLogin(
      route,
      method,
      { email: email, password: password },
      "user"
    )
      .then((data) => {
        const user = data.user;
        dispatch(slice.actions.loginSuccess({ user }));
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          throw { message: "Incorrect Login Details" };
        } else {
          throw error;
        }
      });
  };
}

// ----------------------------------------------------------------------

export function logout() {
  return async (dispatch) => {
    dispatch(slice.actions.logoutSuccess());
  };
}
