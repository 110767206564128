import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Policy.scss";
import ApiService from "../../../Utils/ApiService";
import routes from "../../../Routes/routes.json";
import config from "../../../Config/config";
import { useRef } from "react";

function Privacy() {
  const ref = useRef();
  useEffect(() => {
    fetch(`${config.getBackEndUrl("user")}/static/policy.html`)
      .then((response) => response.text())
      .then((text) => {
        ref.current.innerHTML = text;
      });
  }, [ref.current]);

  return <div ref={ref} className="Privacy" />;
}
console.log("URL PRIVACY", `${config.getBackEndUrl("user")}/static`)

export default Privacy;
