import link_avatar from "../../Assets/img/Links/link_avatar.svg";
import React from 'react';

const Link = ({avatar, user}) => {
    const isValidUrl = (url) => {
        try {
          new URL(url);
          return true;
        } catch (e) {
          return false;
        }
      };
      
    return (
        <div className="links_body_left_contacts_mylinks_content flex flex-ai-c flex-js-fs">
             {user.avatar && isValidUrl(user.avatar) ? (
                <>
                  <img
                    draggable="false"
                    src={user.avatar}
                    className="profile_container_about_left_avatar_img"
                  />
                </>
              ) : (
                <img
                  draggable="false"
                  src={link_avatar}
                  className="profile_container_about_left_avatar_img"
                />
              )}
            <div>
                @{user.username} 
            </div>
        </div>
    );
}

export default Link;