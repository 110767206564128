const connexion = (state = false, action) => {
  switch (action.type) {
    case "DISCONNECTED":
      return (state = true);
    case "CONNEXION":
      return (state = false);

    default:
      return (state = state);
  }
};

export default connexion;
