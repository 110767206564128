import React, { useEffect, useState } from "react";

const Mywallet = () => {
    return (
        <div>
            <div className="page_title">
            My account
            <div className="register_header_path flex flex-js-fs">
                Home <div className="register_header_path_gt">{">"}</div> <span>My account</span>
            </div>
            </div>
            
        </div>
    );
  };
  
  export default Mywallet;