import React from 'react';
import { useState } from 'react';
import ApiService from '../../../Utils/ApiService';
import routes from "../../../Routes/routes.json";

import Button from '../Button';
import Input from '../Input';
import './Contact.scss'

function Contact() {

  const [email, setEmail] = useState("")
  const [content, setContent] = useState("")
  const [name, setName] = useState("")

  const handleSendContact = () => {

    console.log("eto")
    
    let data = new Object()
    data.email = email
    data.content = content 
    data.name = name 

    const route = `${routes.routes.back_end.iconic_user.contact.url}`;
    const method = `${routes.routes.back_end.iconic_user.contact.method}`;

    
    if(
      email != "" &&
      name != "" &&
      content != ""
    ) {
      ApiService.sendingPutData(route, method, data, "user").then((result) => {
        console.log(result)
      })
    }
    
  }
  
  return (
    <form className="contact">
      <Input
        label="What is your name ?"
        placeholder="Enter your name please"
        type="text"
        name="name"
        onChange={(e) => {
          setName(e.target.value)
        }}
      />
      <Input
        label="Your email address"
        placeholder="How can we contact you ?"
        type="email"
        name="email"
        onChange={(e) => {
          setEmail(e.target.value)
        }}
      />
      <Input
        label="How can we help you ?"
        placeholder="Come on, we are here to help you !"
        type="textarea"
        name="content"
        onChange={(e) => {
          setContent(e.target.value)
        }}
      />
      <div className='submit-btn'>
      <Button
        onSubmit={() => handleSendContact()}
      >Submit</Button>
      </div>
    </form>
  );
}

export default Contact;