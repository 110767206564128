import React, { useState } from "react";
import UserItem from "../../../UserItem";
import "./AddMember.scss";
import Swal from "sweetalert2";

export default function AddMember({ onCloseMember, onAddMember }) {
  const [, /*selectedUsers*/ setSelectedUsers] = useState([]);
  const [item, setItem] = useState();
  console.log("user selectionné", item);

  const handleSelectChange = (select) => {
    setItem(select);
    // onItemSelected(select);
  };

  const handleAdd = async () => {
    if (item.length > 0) {
      for (let user of item) {
        await onAddMember(user);
      }
      onCloseMember();
    } else {
      Swal.fire({
        title: "No user selected",
        icon: "warning",
        timer: 1500,
        timerProgressBar: true,
        width: 600,
      });
    }
  };

  return (
    <div className="AddMember">
      <div className="add-title">Select user from your link</div>
      <UserItem
        ListUsers={setSelectedUsers}
        onSelectChange={handleSelectChange}
      />
      <div className="decision-buttons">
        <div className="block-btn cancel">Cancel</div>
        <div className="block-btn confirm" onClick={handleAdd}>
          Add Member
        </div>
      </div>
    </div>
  );
}
