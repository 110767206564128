import { combineReducers } from "redux";
import darkMode from "./darkMode";
import connexion from "./connexion";
import userData from "./userData";
import auth from "../slices/auth";
import user from "../slices/user"

const rootReducer = combineReducers({
  Auth:  auth,
  User: user,
  userData: userData,
  darkMode: darkMode,
  connexion: connexion,
});

export default rootReducer;
