/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useMemo } from "react";
import ApiService from "../../../Utils/ApiService";
import routes from "../../../Routes/routes.json";
import { useSelector, useDispatch } from "react-redux";
import CircleProgressBar from "../../CircleProgressBar";
import confirm_check from "../../../Assets/img/Account/confirm_check.svg";
import RadioInput from "../Settings/RadioButton";
import CustomGroupButton from "../Settings/RadioButton/CustomGroup";
import SettingButton from "./SettingButton";
import Block from "./Block";
import Modal from "../../common/Modal";
import Blocked from "./Block/BlockedItem";
import Groups from "./Group";
import GroupItem from "./Group/GroupItem";
import { updateUsers } from "../../../Redux/slices/user";

const Privacy = (props) => {
  const userConnected = useSelector((state) => state.Auth.user);
  const links = useSelector((state) => state.User.link);
  const [, setAllPrivacy] = useState(null);
  const [, setAllDataPrivacy] = useState({});
  const [postCustomValue] = useState("");
  const [, setUsersPostSearched] = useState(null);
  const [, setDataUsersSelected] = useState({});
  const [seeActivityCustomGroup, setSeeActivityCustomGroup] = useState([]);
  const [, setDataUsersBlockSelected] = useState({});
  const [seeActivityBlockGroup, setSeeActivityBlockGroup] = useState([]);
  const [groupChange, setGroupChange] = useState();
  const dispatch = useDispatch();
  const [user, setUser] = useState([]);

  const [confirmSuccess, setConfirmSuccess] = useState(false);
  const percentage = 100;
  const [progressBar, setProgressBar] = useState(0);
  const [isPublic, setIsPublic] = useState(true);
  const [visit, setVisit] = useState("");
  const [tag, setTag] = useState("");
  const [message, setMessage] = useState("");
  const [myActivity, setmyActivity] = useState("");

  const [activeChoice, setAciveChoice] = useState([]);

  useEffect(() => {
    fetchPrivacy();
  }, []);

  useEffect(() => {
    if (links && links.length > 0) {
      processElementsSearched(
        links.filter((link) => link["status"] === "bind")
      );
    }
  }, [postCustomValue]);

  useEffect(()=> {
    fetchUser();
  },[]);
  useEffect(() => {
    if (user && user.privacy) {
      setVisit(user.privacy.visit_my_profile);
      setTag(user.privacy.tag_me);
      setMessage(user.privacy.message_me);
      setmyActivity(user.privacy.see_my_activity);
    }
    
  }, [user]);

  const fetchUser = () => {
    const routeGetUser = `${routes.routes.back_end.iconic_user.get_user_id.url}`;
    const methodGetUser = `${routes.routes.back_end.iconic_user.get_user_id.method}`;

    ApiService.fetchApiData(
      routeGetUser + "/" + userConnected.id,
      methodGetUser,
      "user"
    )
      .then((result) => {
        setUser(result);
      })
      .catch((err) => {
        console.log("error : ", err.message);
      });
 }

  const handleSuccess = () => {
    setConfirmSuccess(!confirmSuccess);
  };

  const updatePercentage = () => {
    setTimeout(() => {
      setProgressBar(progressBar + 1);
    }, 5);
  };

  useEffect(() => {
    if (percentage > 0) updatePercentage();
  }, [percentage]);

  useEffect(() => {
    if (progressBar < percentage) updatePercentage();
  }, [progressBar]);

  const fetchPrivacy = () => {
    const routePrivacy = `${routes.routes.back_end.iconic_user.user_privacy.url}`;
    const methodPrivacy = `${routes.routes.back_end.iconic_user.user_privacy.get_method}`;
    ApiService.fetchApiData(routePrivacy + user.id, methodPrivacy, "user")
      .then((result) => {
        console.log("result privacy : ", result);
        setAllPrivacy(result);
        setAllDataPrivacy(result.privacy);
        if (result.privacy["see_my_activity"]["custom group"]) {
          let data = {};
          setSeeActivityCustomGroup(
            result.privacy["see_my_activity"]["custom group"]
          );
          result.privacy["see_my_activity"]["custom group"].map((value) => {
            data[value] = true;
          });
          console.log("custom group exist");
          setDataUsersSelected(data);
        } else if (result.privacy["see_my_activity"]["my_links"]) {
          console.log("block group");
          let data = {};
          setSeeActivityBlockGroup(
            result.privacy["see_my_activity"]["my_links"]
          );
          result.privacy["see_my_activity"]["my_links"].map((value) => {
            data[value] = true;
          });
          setDataUsersBlockSelected(data);
        }
      })
      .catch(() => {
        console.log("error get privacy : ");
      });
  };

  const processElementsSearched = (allusers) => {
    let listUsers = [];
    if (postCustomValue === "") {
      setUsersPostSearched(listUsers);
      //setIsSearchActive(false);
    } else {
      for (let index = 0; index < allusers.length; index++) {
        const element = allusers[index];
        if (element.username.includes(postCustomValue)) {
          listUsers.push(element);
        }
      }
      setUsersPostSearched(listUsers);
      //console.log("listUsers searched : ", listUsers);
    }
  };
 
  const toggleActive = useMemo(
    () => (key) => {
      setAciveChoice([key]);
    },
    [activeChoice]
  );

  const [isBlockModal, setIsBlockModal] = useState(false);

  const handleBlockModal = useCallback(() => {
    setIsBlockModal(true);
  }, []);

  const handleBlockModalClose = () => {
    setIsBlockModal(false);
  };

  const resetChanges = () => {
    setVisit('Only my links');
    setTag('Only my links');
    setMessage('Only my links');
    setmyActivity('Only my links');
    setIsPublic(!isPublic);
  }

  const onClose = useCallback(() => {
    setIsBlockModal(false);
  }, []);

  const [isGroupModal, setIsGroupModal] = useState(false);

  const handleModal = useCallback(() => {
    setIsGroupModal(true);
  }, []);

  const handleModalClose = () => {
    setIsGroupModal(false);
  };

  const getLabel = useMemo(
    () => (key) => {
      switch (key) {
        case "everybody":
          return "Everybody";
        case "nobody":
          return "Nobody";
        case "public":
          return 'Only my links'
        default:
          return "";
      }
    },
    [visit, tag, message, myActivity]
  );

  const [allGroup, setAllGroup] = useState([]);

  useEffect(() => {
    fetchGroup();
  }, [isGroupModal, groupChange]);

  const fetchGroup = () => {
    const urlGroup = `${routes.routes.back_end.user.getGroup.url}`;
    const methodGroup = `${routes.routes.back_end.user.getGroup.method}`;
    ApiService.fetchApiData(urlGroup, methodGroup, "user")
      .then((result) => {
        setAllGroup(result);
      })
      .catch(() => {
        console.log("error : ");
      });
  };
 
  const handleGroupChange = (group) => {
    // Handle the received group data from GroupItem
    console.log("groupChange", group);
    setGroupChange(group);
  };
  

  const handleSaveData = (updateField, newValue) => {
    const routeUpdateUser = `${routes.routes.back_end.iconic_user.update_user.url}`;
    const methodUpdateUser = `${routes.routes.back_end.iconic_user.update_user.method}`;
    
    // Prepare the privacy object
    let privacy = {
      account_type: "public", 
      visit_my_profile: visit ?? 'my_links',
      tag_me: tag ?? 'my_links',
      message_me: message ?? 'my_links',
      see_my_activity: myActivity ?? 'my_links'
    };
  
    privacy[updateField] = newValue;
    
    let data = {
        privacy: JSON.stringify(privacy)
    }
     
    ApiService.postExpectFormData(
      `${routeUpdateUser}/${user.username}`,
      methodUpdateUser,
      data,  
      "user"
    )
    .then((result) => {
      dispatch(updateUsers(result));  // Mise à jour des utilisateurs dans Redux
      setAciveChoice([]);  // Réinitialiser les choix actifs
    })
    .catch((err) => {
      console.log("error : ", err.message);
    });
  };
  
  
  const handleSaveVisit = () => {
    handleSaveData("visit_my_profile", visit);
  };
  
  const handleSaveTag = () => {
    handleSaveData("tag_me", tag);
  };
  
  const handleSaveMessage = () => {
    handleSaveData("message_me", message);
  };
  const handleSaveActivity = () => {
    handleSaveData("see_my_activity", myActivity)
  };

  return (
    <div className="privacy">
      <div className="option-wrapper">
        <div className="items">
          <div className="item-select">Account Type</div>
          <div className="side">
            <div className="item-detail">
              {isPublic ? "Profile set to public" : "Profile set to private"}
            </div>
            <label class="switch">
              <input type="checkbox" onClick={() => setIsPublic(!isPublic)} />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
      </div>

      {isPublic && (
        <React.Fragment>
          <div className="option-title">Selection privacy option</div>
          <div className="option-wrapper">
            <div className="items">
              <div className="item-select">Visit on my profile</div>
              <div className="item-value" onClick={() => toggleActive("visit")}>
                <span>{getLabel(visit)}</span>

                <span className="arrow" />
              </div>
            </div>

            {activeChoice.includes("visit") && (
              <div className="choices">
                <div className="choice-title">Who can see my activity</div>
                <div className="choices-list">
                  <div className="choice-content">
                    <RadioInput
                      label="Only my links"
                      value="public"
                      checked={visit}
                      setter={setVisit}
                    />
                    <RadioInput
                      label="Everybody"
                      value="everybody"
                      checked={visit}
                      setter={setVisit}
                    />
                    <RadioInput
                      label="Nobody"
                      value="nobody"
                      checked={visit}
                      setter={setVisit}
                    />
                    <CustomGroupButton />
                  </div>
                  <SettingButton 
                    text="Save changes" 
                    onSubmit={handleSaveVisit}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="option-wrapper">
            <div className="items">
              <div className="item-select">Tags</div>
              <div className="item-value" onClick={() => toggleActive("tags")}>
                <span>{getLabel(tag)}</span>
                <span className="arrow" />
              </div>
            </div>

            {activeChoice.includes("tags") && (
              <div className="choices">
                <div className="choice-title">Who can tag me</div>
                <div className="choices-list">
                  <div className="choice-content">
                    <RadioInput
                      label="Only my links"
                      value="public"
                      checked={tag}
                      setter={setTag}
                    />
                    <RadioInput
                      label="Everybody"
                      value="everybody"
                      checked={tag}
                      setter={setTag}
                    />
                    <RadioInput
                      label="Nobody"
                      value="nobody"
                      checked={tag}
                      setter={setTag}
                    />
                    <CustomGroupButton />
                  </div>
                  <SettingButton 
                    text="Save changes" 
                    onSubmit={handleSaveTag}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="option-wrapper">
            <div className="items">
              <div className="item-select">Send me a message</div>
              <div
                className="item-value"
                onClick={() => toggleActive("message")}
              >
                <span> {getLabel(message)} </span>
                <span className="arrow" />
              </div>
            </div>

            {activeChoice.includes("message") && (
              <div className="choices">
                <div className="choice-title">Who can message me</div>
                <div className="choices-list">
                  <div className="choice-content">
                    <RadioInput
                      label="Only my links"
                      value="public"
                      checked={message}
                      setter={setMessage}
                    />
                    <RadioInput
                      label="Everybody"
                      value="everybody"
                      checked={message}
                      setter={setMessage}
                    />
                    <RadioInput
                      label="Nobody"
                      value="nobody"
                      checked={message}
                      setter={setMessage}
                    />
                    <CustomGroupButton />
                  </div>
                  <SettingButton 
                    text="Save changes"
                    onSubmit={handleSaveMessage}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="option-wrapper">
            <div className="items">
              <div className="item-select">See my Activity</div>
              <div
                className="item-value"
                onClick={() => toggleActive("activity")}
              >
                <span>{getLabel(myActivity)}</span>
                {console.log("Activity ", myActivity)}
                <span className="arrow" />
              </div>
            </div>

            {activeChoice.includes("activity") && (
              <div className="choices">
                <div className="choice-title">Who can see my Activity</div>
                <div className="choices-list">
                  <div className="choice-content">
                    <RadioInput
                      label="Only my links"
                      value="public"
                      checked={myActivity}
                      setter={setmyActivity}
                    />
                    <RadioInput
                      label="Everybody"
                      value="everybody"
                      checked={myActivity}
                      setter={setmyActivity}
                    />
                    <RadioInput
                      label="Nobody"
                      value="nobody"
                      checked={myActivity}
                      setter={setmyActivity}
                    />
                    <CustomGroupButton />
                  </div>
                  <SettingButton 
                    text="Save changes"
                    onSubmit={handleSaveActivity}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="option-title">User privacy option</div>
          <div className="option-wrapper">
            <div className="items">
              <div className="item-select">Custom group</div>
              <div
                className="item-value"
                onClick={() => toggleActive("custom")}
              >
                <span>number of groups ({allGroup?.length})</span>
                <span className="arrow" />
              </div>
            </div>

            {activeChoice.includes("custom") && (
              <div className="choices">
                <div className="choice-title">Group list</div>
                <div className="choices-list">
                  <div className="choice-content">
                    <div className="block-user" onClick={handleModal}>
                      Add group
                    </div>
                    <Modal
                      isOpen={isGroupModal}
                      title="Create custom group"
                      onClose={handleModalClose}
                    >
                      <Groups onClose={handleModalClose} />
                    </Modal>

                    {/* Group */}
                    <div className="blocked-list">
                      <GroupItem onGroupChange={handleGroupChange} />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="option-wrapper">
            <div className="items">
              <div className="item-select">Blocked group</div>
              <div className="item-value" onClick={() => toggleActive("block")}>
                <span>1 user blocked</span>
                <span className="arrow" />
              </div>
            </div>

            {activeChoice.includes("block") && (
              <div className="choices">
                <div className="choice-title">Block list</div>
                <div className="choices-list">
                  <div className="choice-content">
                    <div className="block-user" onClick={handleBlockModal}>
                      Add user
                    </div>
                    <Modal
                      isOpen={isBlockModal}
                      title="Select user to block"
                      onClose={onClose}
                    >
                      <Block onClose={handleBlockModalClose}/>
                    </Modal>

                    {/* unblock */}
                    <div className="blocked-list">
                      <Blocked />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </React.Fragment>
      )}

      <div className="reset-container">
        <SettingButton
         text="Reset all changes"
         onSubmit ={resetChanges}
        />
      </div>

      {confirmSuccess && (
        <div className="flex">
          <div className="exchange_popup flex flex-fd-c flex-js-fs">
            <CircleProgressBar
              trailStrokeColor="#FD55BA"
              strokeColor="#9B8EFF"
              percentage={progressBar}
              innerText="complete"
              radioCircle1={10}
              radioCircle2={12}
              y={19}
              size={38}
              x={19}
              className=""
              withtext={false}
              strokeDasharray={`${progressBar} ${100 - progressBar}`}
              strokeDashoffset={25}
            />
            <img
              src={confirm_check}
              alt=""
              className="transferout_popup_success"
            ></img>
            <div className="transferout_popup_confirm_text flex flex-ai-c">
              {" "}
              Information saved
            </div>
          </div>
          <div id="overlay" onClick={() => handleSuccess()}></div>
        </div>
      )}
      {console.log("seeActivityCustomGroup : ", seeActivityCustomGroup)}
      {console.log("seeActivityBlockGroup : ", seeActivityBlockGroup)}
    </div>
  );
};

export default Privacy;
